import React, { useState, useEffect } from 'react';
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation } from 'react-router-dom';
import { usePersistedDataStore } from "../store";
import axiosInstance from "../../axiosConfig";
import AddIcon from '@mui/icons-material/Add';
import ReactDOM from 'react-dom';
// If searchdata is blank, search from getCompsheet as it curr does.
// This needs to be updated structurally so the user can type and search into DB not on data already loaded.
function CompSearch({ handleClose, compSelectCallback, searchData, placeholderText='PID or Address' }) {
  // Searchdata needs to be structured as an array of objects, and must have the "parcel_id" and "Address" keys
  // UPDATE YOUR SEARCHFUNCTION 
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);
    const [isPidSearch, setIsPidSearch] = useState(false);
    const getCompSheet = usePersistedDataStore((state) => state.compSheet);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const comp = parseInt(params.get('comp')) - 1;
    const inputRef = React.useRef(null);
    const handleInputChange = (event) => {
      const value = event.target.value;
    
      // Update the state directly without additional checks that could interfere with spaces
      setSearchTerm(value);
    
      // Clear suggestions and loading state only when the input is empty
      if (value === '') {
        setLoading(false);
        setSuggestions([]);
        setDebouncedTerm('');
      } else {
        setLoading(true);
      }
    };
    const [dropdownStyle, setDropdownStyle] = useState({});
      // Position the dropdown using `getBoundingClientRect`
  useEffect(() => {
    if (inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      setDropdownStyle({
        position: 'absolute',
        left: `${rect.left}px`,
        top: `${rect.bottom + window.scrollY}px`,
        width: `${rect.width}px`,
        zIndex: 1000,
      });
    }
  }, [inputRef.current]);

    // Debounce effect: update debouncedTerm only after user stops typing for 500ms
    useEffect(() => {
        const handler = setTimeout(() => {
            if (searchTerm.length > 0) {
                setDebouncedTerm(searchTerm);
            } else if (loading === true || suggestions.length > 0 || debouncedTerm !== '') {
                // Reset states to default if there is no search term
                setLoading(false);
                setSuggestions([]);
                setDebouncedTerm('');
            }
        }, 500); // 500ms debounce time

        // Cleanup the timeout if searchTerm changes before 500ms
        return () => clearTimeout(handler);
    }, [searchTerm]);


    // This search works for hte subject search, but for generalized comp search this is not cutting it.
    // Perform the search when debouncedTerm changes
    useEffect(() => {
      const fetchData = async () => {
        if (debouncedTerm) {
          const isPidSearchPattern = /^[A-Za-z]\d{4}/.test(debouncedTerm) && /-/.test(debouncedTerm);
          setIsPidSearch(isPidSearchPattern);
    
          // this is termporary
          let searchLocale = isPidSearchPattern
            ? getCompSheet[comp]?.parcel_id
            : getCompSheet[comp]?.Address;

          if(!searchData){
            searchData = searchLocale
          }
          if (searchData) {
            // Prepare searchData with suggestion keys and index preservation
            searchLocale = searchData.map((item, index) => {
              if (typeof item === "string") {
                // Wrap string as an object with index
                // TEMPORARILY USING COMPSHEET TO PUT IN PID.
                return { suggestion: item, index, pid: getCompSheet[comp]?.parcel_id[index] };
              } else if (item && typeof item === "object") {
                // Ensure each object has a "suggestion" key and preserve index
                const suggestionKey = isPidSearchPattern ? "PID" : "Address";
                return {
                  suggestion: item.suggestion || item[suggestionKey],
                  index,
                  pid: getCompSheet[comp]?.parcel_id[index]
                };
              }
              return null; // Filter out invalid items
            }).filter(Boolean); // Remove null or undefined entries
          }
    
          // Perform search on the transformed searchLocale
          const found = searchLocale?.filter((item) =>
            item?.suggestion?.toLowerCase().includes(debouncedTerm.toLowerCase())
          );
    
          setSuggestions(found || []);
          setLoading(false);
        }
      };
    
      fetchData();
    }, [debouncedTerm, getCompSheet, comp, searchData]);
    

    return (
      <div className="flex h-full w-full items-center">
        <TextField
          ref={inputRef}
          placeholder={placeholderText}
          variant="standard"
          fullWidth
          value={searchTerm}
          onChange={handleInputChange}
          autoComplete="off"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: loading && <CircularProgress className='absolute right-2'  size={20} />,
          }}
        />
  
        {/* Render suggestions in a portal */}
        {suggestions.length > 0 &&
          ReactDOM.createPortal(
            <ul
              className="bg-white border shadow-md p-1 rounded-md overflow-y-auto"
              style={{
                ...dropdownStyle,
                maxHeight: '200px', // Allow scrolling for long lists
              }}
            >
              {suggestions.map(({ suggestion, index, pid }, suggestionIndex) => (
                <li
                  key={suggestionIndex}
                  className="px-4 py-2 cursor-pointer hover:bg-[#F1F5F9] hover:rounded-md"
                  onClick={() => {
                    compSelectCallback({ suggestion, index, pid });
                    setSuggestions([]);
                    setSearchTerm('');
                    setDebouncedTerm('');
                  }}
                >
                  {suggestion}
                </li>
              ))}
            </ul>,
            document.body // Portal renders outside the restrictive parent
          )}
  
        {/* No results */}
        {debouncedTerm && suggestions.length === 0 &&
          ReactDOM.createPortal(
            <ul
              className="bg-white border shadow-md w-fit p-1 rounded-md"
              style={{
                ...dropdownStyle,
                maxHeight: '200px',
              }}
            >
              <li className="px-4 py-2 text-center cursor-pointer">
                No matching addresses
              </li>
            </ul>,
            document.body
          )}
      </div>
    );
  }
export default CompSearch;
