import React from 'react';
import { NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import propriety from '../images/propriety-32.png';

function ProprietyLogo() {
  return (
    <NavLink to="/" className="flex h-full cursor-pointer" style={{ textDecoration: 'none' }}>
      <Box
        component="img"
        src={propriety}
        alt="Logo"
        // sx={{
        //   height: 32, // Adjust height as needed
        //   width: 32,
        // }}
      />
      <Typography
        variant="h5"
        noWrap
        component="span"
        sx={{
          flexGrow: 1,
          fontFamily: 'monospace',
          fontWeight: 700,
          color: 'inherit',
          textDecoration: 'none',
          letterSpacing: '.1rem', // this is a question, do we want to have it more or less spaced?
          marginLeft: -0.5,
          
        }}
      >
        ropriety
      </Typography>
    </NavLink>
  );
}

export default ProprietyLogo;