import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useRef } from 'react';
import {isEqual} from 'lodash';

const areEqual = (prevProps, nextProps) => {
  return isEqual(prevProps.latLongs, nextProps.latLongs)
};

function Map({ latLongs }) {
  const mapRef = useRef(null); // Ref for the map instance
  const markersRef = useRef([]); // Ref for storing markers
  const circlesRef = useRef([]); // Ref for storing circles

  // CIRCLES STYLING IS FUCKED. Get this cleaned upa nd centered.
  // house within circle specificallky might be the only issue tbh.

useEffect(() => {
  if (!mapRef.current) {
    // Initialize the map only once
    mapRef.current = L.map('map', { attributionControl: false });

    // Add the tile layer
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© OpenStreetMap contributors',
      maxZoom: 19,
    }).addTo(mapRef.current);
  }

  // Remove previous markers and circles
  markersRef.current.forEach((marker, index) => {
    if (!latLongs[index] || !latLongs[index]?.latitude || !latLongs[index]?.longitude) {
      mapRef.current.removeLayer(marker);
      if (circlesRef.current[index]) {
        mapRef.current.removeLayer(circlesRef.current[index]);
      }
    }
  });

  // Update markers and circles
  latLongs.forEach((coord, i) => {
    const { latitude, longitude } = coord;

    if (!latitude || !longitude) {
      console.error(`Missing latitude/longitude data for comp ${i}.`);
      return;
    }

    if (markersRef.current[i]) {
      markersRef.current[i].setLatLng([latitude, longitude]);
      circlesRef.current[i].setLatLng([latitude, longitude]);
    } else {
      const markerContainer = document.createElement('div');
      markerContainer.className = 'custom-marker-container';

      const svgIcon = document.createElement('div');
      svgIcon.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" fill="#269AE6" viewBox="0 0 24 24" width="22" height="22" style="display: block; margin: auto;"
        >
          <path d="M19.707 9.293l-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
        </svg>
      `;
      
      markerContainer.appendChild(svgIcon);

      const numberElement = document.createElement('div');
      numberElement.className = 'custom-marker-number';
      numberElement.textContent = i > 0 ? i : '';
      markerContainer.appendChild(numberElement);

      const circleColor = i === 0 ? '#FFBA00' : '#F1F5F9';
      const circle = L.circleMarker([latitude, longitude], {
        color: circleColor,
        fillColor: circleColor,
        fillOpacity: 0.8,
        radius: 12,
        zIndexOffset: i === 0 ? 999 : 0,
      }).addTo(mapRef.current);

      const marker = L.marker([latitude, longitude], {
        icon: L.divIcon({
          className: 'custom-marker pr-1 pt-[2px]',
          html: markerContainer,
          iconSize: [30, 30], // Adjusted size
          iconAnchor: [11, 11], // Center the marker
        }),
        zIndexOffset: i === 0 ? 1000 : 0,
      }).addTo(mapRef.current);

      markersRef.current[i] = marker;
      circlesRef.current[i] = circle;
    }
  });

  // Auto-zoom to display all markers
  if (latLongs.length > 0) {
    const markerCoordinates = latLongs
      .filter(coord => coord.latitude && coord.longitude)
      .map(coord => [coord.latitude, coord.longitude]);

    mapRef.current.fitBounds(markerCoordinates);
  }

  return () => {
    markersRef.current.forEach(marker => {
      mapRef.current.removeLayer(marker);
    });
    circlesRef.current.forEach(circle => {
      mapRef.current.removeLayer(circle);
    });
    markersRef.current = [];
    circlesRef.current = [];
  };
}, [latLongs]);


  return <div className='dark:bg-boxdark bg-white mb-1' id='map' />;
}

export default React.memo(Map, areEqual);
