import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Magic } from "magic-sdk"; // Import Magic SDK

// Create the AuthContext
const AuthContext = createContext();
const magic = new Magic(process.env.REACT_APP_MAGIC_PUBLISHABLE_KEY); // Initialize Magic SDK
// Provide AuthContext to the app
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Track initialization

  // On component mount, load user state from localStorage
  useEffect(() => {
    const storedUser = localStorage.getItem('userInfo');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    setLoading(false); // Set loading to false after check
  }, []);

  // Login function
  const login = (userData) => {
    // Update this, so that it extracts the relevant key pairs from the userData object
    // and then stores them in localStorage
    console.log(userData)
    localStorage.setItem('userInfo', JSON.stringify(userData));
    setUser(userData);
  };

  // Logout function
  const logout = async () => {
    localStorage.removeItem('userInfo');
    setUser(null);
    await magic.user.logout();
    console.log('User logged out');
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook for consuming AuthContext
export const useAuth = () => useContext(AuthContext);
