import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { generateResponse } from './MockBackend';
import ChatWindow from './ChatWindow';
import ChatInput from './ChatInput';
import ConversationHistory from './ConversationHistory';

const ChatContainer = () => {
    const navigate = useNavigate();
    const { conversationID } = useParams(); // Extract conversation ID from the URL
    const inputRef = useRef(null);
    const messagesEndRef = useRef(null); // Ref to scroll to the bottom

    const [conversationHistory, setConversationHistory] = useState(() => {
        const savedConversations = localStorage.getItem('conversationHistory');
        return savedConversations ? JSON.parse(savedConversations) : [];
    });

    const [currentConversation, setCurrentConversation] = useState(null);

    const handleSetConversation = useCallback((id) => {
        const selectedConversation = conversationHistory.find(convo => convo.id === id);
        if (selectedConversation) {
            setCurrentConversation(selectedConversation);
        }
    }, [conversationHistory]);

    useEffect(() => {
        if (conversationID) {
            handleSetConversation(parseInt(conversationID, 10)); // Ensure ID is a number
        }
    }, [conversationID, handleSetConversation]);

    const handleStartNewConversation = useCallback(() => {
        const newConversation = {
            id: Date.now(),
            name: `Conversation ${conversationHistory.length + 1}`,
            messages: []
        };
        setConversationHistory(prev => [...prev, newConversation]);
        setCurrentConversation(newConversation);
        navigate(`/chat/${newConversation.id}`);
    }, [conversationHistory, navigate]);

    const handleSendMessage = useCallback((userMessage) => {
        const newMessage = { sender: 'user', text: userMessage };

        if (!currentConversation) {
            handleStartNewConversation();
        }

        const botResponse = generateResponse(userMessage);

        setCurrentConversation((prev) => {
            const updatedConversation = {
                ...prev,
                messages: [
                    ...(prev?.messages || []),
                    newMessage,
                    { sender: 'bot', text: botResponse, hasTyped: false }
                ],
            };

            setConversationHistory((prevHistory) => {
                const updatedHistory = prevHistory.some(convo => convo.id === updatedConversation.id)
                    ? prevHistory.map(convo =>
                          convo.id === updatedConversation.id ? updatedConversation : convo
                      )
                    : [...prevHistory, updatedConversation];

                return updatedHistory;
            });

            return updatedConversation;
        });
    }, [currentConversation, handleStartNewConversation]);

    // Scroll to the bottom after a new message is added (especially bot response)
    useEffect(() => {
        if (messagesEndRef.current) {
            // Ensure we scroll to the last message (bot response)
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [currentConversation?.messages]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key !== 'Enter' && inputRef.current && document.activeElement !== inputRef.current) {
                inputRef.current.focus();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    // For your typewriter effect:
    const onTypingComplete = useCallback((messageIndex) => {
        console.log('Conversation ID:', conversationID);
    
        const conversationIndex = conversationHistory.findIndex(convo => convo.id === parseInt(conversationID, 10));
        console.log('Conversation index in history:', conversationIndex);
        console.log('Conversation history:', conversationHistory[conversationIndex].messages[messageIndex]);
    
        // Make a shallow copy of the conversation history
        const updatedConversationHistory = [...conversationHistory];
    
        // Update the specific message in the copied conversation
        updatedConversationHistory[conversationIndex] = {
            ...updatedConversationHistory[conversationIndex],
            messages: [
                ...updatedConversationHistory[conversationIndex].messages.slice(0, messageIndex),
                {
                    ...updatedConversationHistory[conversationIndex].messages[messageIndex],
                    hasTyped: true,
                },
                ...updatedConversationHistory[conversationIndex].messages.slice(messageIndex + 1),
            ],
        };
    
        // Set the updated conversation history
        setConversationHistory(updatedConversationHistory);
    }, [conversationHistory, conversationID, setConversationHistory]);

    return (
        <div className="flex h-full">
            <div className="sidebar w-1/4 h-full p-4 bg-gray-100">
                <ConversationHistory
                    conversationHistory={conversationHistory}
                    handleStartNewConversation={handleStartNewConversation}
                    handleSetConversation={(id) => {
                        handleSetConversation(id);
                        navigate(`/chat/${id}`);
                    }}
                />
            </div>

            <div className="flex-grow w-full flex flex-col relative">
                {/* Always present header */}
                <div className="bg-white h-12 w-full text-center">
                    {/* <div>header placeholder</div>  */}
                </div>

                {/* Main content area */}
                <div className="flex-grow w-full flex flex-col justify-between overflow-hidden">
                    {/* Chat Window */}
                    <div className="flex-grow w-full px-4 pb-44 overflow-y-auto">
                        {currentConversation && currentConversation.messages.length > 0 ? (
                            <div className="gap-4 text-base md:gap-5 lg:gap-6 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem] mx-auto">
                                <ChatWindow
                                    conversation={currentConversation.messages}
                                    onTypingComplete={onTypingComplete}
                                />
                                <div ref={messagesEndRef} /> {/* Ref is placed here at the bottom */}
                            </div>
                        ) : (
                            <div className="flex m-auto h-full flex-col justify-center items-center text-center w-3/5 max-w-3/5 mb-16">
                                <div className="mb-4">What can we help with?</div>
                                <ChatInput onSend={handleSendMessage} ref={inputRef} />
                            </div>
                        )}
                    </div>

                    {/* Fixed chat input at the bottom */}
                    {currentConversation && currentConversation.messages.length > 0 && (
                    <div className="absolute bottom-0 left-0 w-full bg-white h-20">
                        <div className="flex justify-center items-center h-full mx-auto gap-4 text-base md:gap-5 lg:gap-6 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem] min-w-3/5">
                            <ChatInput onSend={handleSendMessage} ref={inputRef} />
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChatContainer;
