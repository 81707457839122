import { useEffect } from 'react';
import debounce from 'lodash.debounce'; // Ensure lodash.debounce is installed

const useResizeHandler = (callback, delay = 300) => {
  useEffect(() => {
    // Ensure the callback is wrapped in debounce
    const debouncedResize = debounce(() => {
      callback();
    }, delay);

    // Attach the resize event listener
    window.addEventListener('resize', debouncedResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('resize', debouncedResize);
      debouncedResize.cancel(); // Cancel any pending debounced calls
    };
  }, [callback, delay]); // Dependencies include callback and delay
};

export default useResizeHandler;
