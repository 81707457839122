import React, { useState, useMemo, useCallback } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { usePersistedDataStore } from '../store';
import CompSearch from './CompSearch';
import { useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import SubjectDataGridRow from './SubjectDataGridRow';
import {calculateHeaders} from '../../common/CalculateHeaderFlex';

const NegotiationTable = ({
  rows,
  subject,
  updateNegotiationComps,
  handleSetPropertyInfo,
  unsavedChangesRef,
  handleDeleteProperty,
  headers,
  tableProps,
  handleAddressClick,
}) => {

  // State for column visibility
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
    const savedModel = localStorage.getItem('columnVisibilityModel');
    return savedModel
      ? JSON.parse(savedModel)
      : {
          PropertyAssessment: false,
          Town: false,
          ZipCode: false,
          fullBaths: false,
          halfBaths: false,
          SDName: false,
          View: false,
          parcel_id: false,
          MLSNumber: false,
        };
  });

  // Memoize the column visibility change handler
  const handleColumnVisibilityChange = useCallback((newModel) => {
    setColumnVisibilityModel(newModel);
  }, []);

  // Memoize the temporary function
  const temporaryFunction = useCallback(
    (suggestion) => {
      // from 'suggestion' extract the parcel id from that index (this passes in index, but )
      updateNegotiationComps(suggestion.pid);
    },
    [updateNegotiationComps]
  );

  // Memoize the index column
  const indexColumn = useMemo(
    () => ({
      field: 'actions',
      headerName: 'Remove',
      width: 65,
      sortable: false,
      renderCell: (params) => {
        console.log(params);
        const hasAddress = params.row?.PropertyInfo?.Address !== '';
        const idDisplay = (params.row?.id ?? 0) + 1;
  
        return (
          <div className="relative text-center cursor-pointer h-full group">
            {/* Default content */}
            <div
              className={`${hasAddress ? 'group-hover:hidden' : ''} h-full`}
            >
              {hasAddress ? idDisplay : <AddIcon style={{ color: '#9e9e9e' }} />}
            </div>
  
            {/* Hover content */}
            {hasAddress && (
              <div className="h-full items-center justify-center hidden group-hover:flex">
                <CloseIcon
                  className="text-red-500 font-bold"
                  onClick={() => handleDeleteProperty(params.row)}
                />
              </div>
            )}
          </div>
        );
      },
    }),
    [handleDeleteProperty] // Ensure dependencies are correct
  );

  // Memoize the processed columns
  const columns = useMemo(() => {
    // Replace the 'actions' column in headers with indexColumn
    const updatedHeaders = headers.map((header) =>
      header.field === 'actions' ? indexColumn : header
    );
    
    return updatedHeaders.map((header) => {
      const isPropertyInfo = header.field === 'PropertyInfo';
      const isActions = header.field === 'actions';
      const isSalePrice = header.field === 'SalePrice'; // Add check for SalePrice
    
      return {
        ...header,
        ...(isPropertyInfo && { minWidth: 180 }),
        // This rendercell is so heinous, we are just combining a bunch of these into one.
        // it works fine because there are so few rows that there aren't real performance issues. Still a stupid way to do this though.
        renderCell: (params) => {
          const isAddressBlank = params.row?.PropertyInfo?.Address === '' || false;
    
          // Special rendering for PropertyInfo
          if (isAddressBlank && isPropertyInfo) {
            return (
              <div className="h-full items-center"
              onKeyDown={(e) => e.stopPropagation()} // Prevent DataGrid from intercepting Space
              >
                <CompSearch
                  compSelectCallback={temporaryFunction}
                  placeholderText="PID or Address"
                />
              </div>
            );
          }else if (isPropertyInfo) {
            // here, return the styled address, with onclick handler to set the value
            return (
            <span
              onClick={()=>handleAddressClick(params.row)}
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              className="flex-grow"
            >
              {params.row.PropertyInfo.Address}
            </span>
            )};
    
          // Special rendering for actions
          if (isActions) {
            return (
              <div className="relative text-center cursor-pointer h-full group">
                {/* Default content */}
                <div
                  className={`${!isAddressBlank ? 'group-hover:hidden' : ''} h-full`}
                >
                  {!isAddressBlank ? params.row.id + 1 : <AddIcon style={{ color: '#9e9e9e' }} />}
                </div>
    
                {/* Hover content */}
                {!isAddressBlank && (
                  <div className="h-full items-center justify-center hidden group-hover:flex">
                    <CloseIcon
                      className="text-red-500 font-bold"
                      onClick={() => handleDeleteProperty(params.row)}
                    />
                  </div>
                )}
              </div>
            );
          }
    
          // Special handling for SalePrice
          if (isSalePrice) {
            const value = params.value;
            if (value === 0 || value === '') {
              return <div style={{ color: 'transparent' }}>—</div>; // Render blank if 0 or ''
            }
            return Number(value).toLocaleString(); // Otherwise, format as a number
          }
    
          // Default rendering for other fields
          if (isAddressBlank) {
            return <div style={{ color: 'transparent' }}>—</div>;
          }
    
          const value = params.value;
          if (value instanceof Date) {
            return value.toLocaleDateString(); // Format dates
          }
    
          if (!isNaN(value)) {
            return Number(Number(value).toFixed(0)).toLocaleString() || '';
          }
    
          return params.value || '';
        },
      };
    });
    
  }, [headers, columnVisibilityModel, temporaryFunction, indexColumn]);


  const calculateFlexForHeaders = useCallback(() => {
    // const filteredHeaders = columns.filter((header) => {
    //   return columnVisibilityModel[header.field] !== false;
    // });
    const containerWidth = document.documentElement.clientWidth - 50

    return calculateHeaders(columns, columnVisibilityModel, containerWidth);
  }, [columnVisibilityModel, headers]);

  // // just directly call on component load, was having issues w/ passing down correct flex to subject row.
  const headerResult = useMemo(() => calculateFlexForHeaders(), [calculateFlexForHeaders]);
  console.log(headerResult)
  return (
    <div style={{ height: 450, width: '100%' }} className="overflow-visible">
      <DataGridPro
        {...tableProps}
        rows={rows}
        hideFooter
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleColumnVisibilityChange}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableRowSelectionOnClick
        slots={{
          toolbar: () => (
            <div className="flex justify-between items-center">
              <SubjectDataGridRow
                subject={subject}
                updateRefWithSubj={{}} // Replace with actual function or prop
                handleSetPropertyInfo={handleSetPropertyInfo}
                unsavedChangesRef={unsavedChangesRef}
                headers={headerResult}
                columnVisibilityModel={columnVisibilityModel}
              />
            </div>
          ),
        }}
      />
    </div>
  );
};

// Memoize the NegotiationTable component
export default React.memo(NegotiationTable);
