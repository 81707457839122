import React, {useMemo, useCallback, useEffect} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {usePersistedDataStore, useCompStore} from '../store';
import AdjustmentsPopover from './AdjustmentsPopover';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@mui/material/Popover';
import CompNavigation from './CompNavigation';
import Tooltip from '@mui/material/Tooltip';
import debounce from 'lodash/debounce';
import InputAdornment from '@mui/material/InputAdornment';
import {NumericFormat} from "react-number-format";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import CaseNotesInput from './CaseNotesInput';
import LoopIcon from '@mui/icons-material/Loop';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import { blue } from '@mui/material/colors';

function a11yProps(index) {
  // this some MUI shit. straight from chat. for the active / transitioned blue underline I believe.
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  // If you have an input you want to automatically be formatted, use this
  export const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
  
    return (
      <NumericFormat
        {...other}
        getInputRef={ref} // Pass the ref properly to the input element
        thousandSeparator
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isnumericstring='true' // Correct camelCase for React prop
      />
    );
  });

const TableCustomToolbar = ({ globalCompRef, handleUpdateStateCallback, offerAmount, subject, view, viewCallback, runDate, regularAvg, compAvg, caseNotes, changeCaseNotes }) => {
  // this input changes from controlled to uncontrolled. Haven't really looked into it RE: casenotes. At some point do this.
    const [value, setValue] = useState(0); // using local state, and re-rendering the entir etable when changing.
    const [isEditing, setIsEditing] = useState(false);
    const getAdjustments = useCompStore((state)=> state.adjustments)
    const getEvalDate = usePersistedDataStore((state) => state.evalDate);
    const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj)
    const setNegotiationObj = usePersistedDataStore((state)=> state.setNegotiationObj)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const comp = useMemo(() => parseInt(queryParams.get('comp')) - 1 || 0, [queryParams]);
    const [selectedKey, setSelectedKey] = useState("selectedComps");
    const handleSelectChange = (event) => {
      setSelectedKey(event.target.value);
    };
    const [showAVs, setShowAVs] = useState(false);
    const [isThirdTabClicked, setIsThirdTabClicked] = useState(false);

    // you should put the

    // You need to multiply all the dollar values you display by getRar if this is true.
    // Now that you have this working, idk that there is any reason to have this memoized?
    const memoizedOfferAmount = useMemo(() => Math.round(offerAmount, 0) || '', [offerAmount]);
    const memoizedCaseNotes = useMemo(() => caseNotes, [caseNotes]);
    const [isFocused, setIsFocused] = React.useState(false);
      const [isHovered, setIsHovered] = React.useState(false);

      const isTooltipOpen = isFocused || isHovered;

    // implement regex to ensure numeric inputs only -- for offer updating
    const handleChange = (event) => {
      const value = event.target.value;
      // Ensure only numeric inputs using regex
      if (/^\d*\.?\d*$/.test(value)) {
      debouncedOnValueChange(value);
      }
    };

    // Debounced function to call when input changes
    const debouncedOnValueChange = useCallback(
      debounce((value) => {
        const offerValue = parseInt(value) || null;
    
        // Check if the new value is the same as the existing value
        const currentOfferValue = getNegotiationObj.cases[comp]?.OfferValue;
        if (currentOfferValue === offerValue) {
          // console.log('No update needed, values are the same.');
          return; // Exit early if the values are the same
        }
    
        // Create a new negotiation object with a direct update
        const updatedCases = [...getNegotiationObj.cases];
        updatedCases[comp] = {
          ...updatedCases[comp],
          OfferValue: offerValue,
        };
    
        const updatedNegotiationObj = {
          ...getNegotiationObj,
          cases: updatedCases,
        };
    
        // Set the updated negotiation object
        setNegotiationObj(updatedNegotiationObj);
      }, 300),
      [comp, getNegotiationObj, setNegotiationObj]
    );
  
    // regularAvg
    // calculate pctAdj using ifmv - compAvg if view is not regular, if regular view use (ifmv-regularAvg)/ifmv
    const pctAdj = useMemo(() => {
      const IFMVTown = getNegotiationObj.cases[comp].IFMVTown;
    
      // Select the appropriate average based on selectedKey
      const selectedAvg = selectedKey === "selectedComps" ? compAvg.selectedComps : compAvg.top5;
    
      // Handle cases where selectedAvg might be a string
      const avg = typeof selectedAvg === "string" ? 0 : selectedAvg;
    
        return (avg - IFMVTown) / IFMVTown; // Flipped formula for other views
    }, [regularAvg, compAvg, view, comp, selectedKey]);


    // update this to be using the input value instead of the offer amount.
    const offerPctAdj = useMemo(() => {
      const IFMVTown = getNegotiationObj.cases[comp].IFMVTown;
      return (memoizedOfferAmount - IFMVTown) / IFMVTown
    }, [memoizedOfferAmount, comp]);
  
    // Determine the styling based on pctAdj value
    const pctAdjClass = pctAdj < 0 ? 'text-green-600' : 'text-red-500';

    // offer pct adj - handle color styling for exact matches (ie: the IFMV has some decimals so it may be -0.0000001 if equal.)
    const offerPctAdjClass = offerPctAdj < -0.0001 ? 'text-green-600' : 'text-red-500';


    const getRar = usePersistedDataStore((state) => state.rar);
    const getEq = usePersistedDataStore((state) => state.eq);
    const formatEvalDate = (dateString) => {
      if (!dateString) return ''; // Handle case where date is null or undefined
      const [year, month, day] = dateString.split('-'); // Split by '-'
      return `${month}/${day}/${year}`; // Rearrange to 'mm/dd/yyyy'
    };

    // minor inefficency on rerenders of eval date.
    // console.log('TableCustomToolbar rendered');
    const formattedEvalDate = formatEvalDate(getEvalDate);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleViewChange = (event, newValue) => {
      setValue(newValue);
      viewCallback(newValue)
      if(newValue === 2 && selectedKey !== 'selectedComps') {
        setSelectedKey('selectedComps')
      }
      if (newValue === 2 && !isThirdTabClicked) {
        setIsThirdTabClicked(true);
      }
    };



    const handleDeleteThirdTab = (event) => {
      event.stopPropagation(); // Prevent triggering tab switch
      setIsThirdTabClicked(false);
      setValue(0)
      viewCallback(0)
    };

    return (
        <div className=''>
          {/* castenotes / chat input */}
          {/* chat piece to be built. */}
          <div className='flex flex-grow'>
            <CaseNotesInput
              initialValue={memoizedCaseNotes} // Pass the current value as initial
              onCaseNotesChange={changeCaseNotes} // Pass the change handler
            />
          </div>
      
          <div className="flex content-center items-center justify-between">
            {/* Section 1: Tabs navigation */}
            <Box
              className="flex self-end w-1/3 items-end"
            >
              {/* three selection tabs for regular, court, or scratch */}
              <Tabs
                value={value}
                onChange={handleViewChange}
                aria-label="basic tabs example"
                sx={{
                  minHeight: '30px',
                  borderBottom: 1,
                  borderColor: 'divider',
                  alignItems: 'flex-start', // Align tabs to the top
                }}
                TabIndicatorProps={{
                  sx: {
                    height: '2px',
                  },
                }}
              >
                <Tab
                  label="Analysis"
                  sx={{
                    minHeight: '30px',
                    padding: '4px 12px',
                    fontSize: '0.875rem',
                  }}
                />
                <Tab
                  label="Court"
                  sx={{
                    minHeight: '30px',
                    padding: '4px 12px',
                    fontSize: '0.875rem',
                  }}
                />
                <Tab
                  label={
                    isThirdTabClicked ? (
                      <div className={`h-full items-center ${value===2 ? 'cursor-default' :'cursor-pointer'}`}
                        style={{
                          position: 'relative',
                          display: 'flex',
                          alignItems: 'center',
                          paddingLeft: '8px',
                          paddingRight: '8px',

                        }}
                      >
                        <span className='flex h-full items-center justify-center'>1</span>
                        <CloseIcon
                          sx={{
                            position: 'absolute',
                            top: '-4px',
                            right: '-10px',
                            fontSize: '1rem',
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={handleDeleteThirdTab}
                        />
                      </div>
                    ) : (
                      <AddIcon 
                      sx={{
                        transition: 'color 0.2s',
                        color: 'inherit',
                        '&:hover': {
                          color: blue[700], // MUI primary blue on hover
                        },
                      }}
                      />
                    )
                  }
                  sx={{
                    minHeight: '30px',
                    fontSize: '0.875rem',
                    padding: '2px 8px', // Compact padding for smaller tab
                    minWidth: 'auto', // Prevent default tab width
                    backgroundColor: 'transparent',
                    '&:hover': !isThirdTabClicked && {
                      cursor: 'pointer',
                      backgroundColor: '#f0f0f0', // Gray background on hover
                    },
                  }}
                />
              </Tabs>
                  {/* DISPLAY A YELLOW HAZARD ICON NEXT TO CLIENT IF ITS A REPID CASE (subject.RepID !== '') */}
                  {/* Client name and SCAR Index # */}
                  <div className="text-sm ml-2 flex flex-col flex-grow justify-center w-[135px]">
                    {(getNegotiationObj.cases[comp].Name || getNegotiationObj.cases[comp].SCARIndexNumber) ? (
                      <>
                        {/* Client name with ellipsis overflow and tooltip */}
                        {getNegotiationObj.cases[comp].Name ? (
                          <div className="text-xs overflow-hidden text-ellipsis whitespace-nowrap">
                            <span className="font-bold mr-1 pl-2">Client:</span>
                            <Tooltip
                              title={getNegotiationObj.cases[comp].Name}
                              disableHoverListener={getNegotiationObj.cases[comp].Name.length <= 12}
                            >
                              <span>{getNegotiationObj.cases[comp].Name}</span>
                            </Tooltip>
                          </div>
                        ) : (
                          <div className="text-xs opacity-50 pl-2">Client: N/A</div>  // {/* Placeholder if no client name */}
                        )}

                        {/* SCAR ID with placeholder if missing */}
                        {getNegotiationObj.cases[comp].SCARIndexNumber ? (
                          <div className="text-xs overflow-hidden text-ellipsis whitespace-nowrap">
                            <span className="font-bold mr-1 pl-2">SCAR ID:</span>
                            <Tooltip
                              title={getNegotiationObj.cases[comp].SCARIndexNumber}
                              disableHoverListener={getNegotiationObj.cases[comp].SCARIndexNumber.length <= 11}
                            >
                              <span>{getNegotiationObj.cases[comp].SCARIndexNumber}</span>
                            </Tooltip>
                          </div>
                        ) : (
                          <div className="text-xs opacity-50 rounded-lg pl-2">
                            <span className=' bg-yellow-500'>SCAR ID: N/A
                            </span>
                          </div>  // {/* Placeholder if no SCAR ID */}
                        )}

                        {/* Including subject starting AV here */}
                        <div className='flex'>
                        <span className="font-bold mr-1 pl-2 text-xs">Subj AV:</span>
                               <span className=' font-normal text-xs'>
                          {Math.round(subject.PropertyAssessment).toLocaleString()+` `}
                          {/* AV */}
                        </span>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* Placeholders if neither Name nor SCAR ID exists */}
                        <div className="text-xs opacity-50 pl-2">Client: N/A</div>
                        <div className="text-xs opacity-50 bg-yellow-500 rounded-lg pl-2">SCAR ID: N/A</div>
                      </>
                    )}
                  </div>
            </Box>

          {/* Section 2: Toolbar content */}
          <div className=" w-1/3 justify-center ">
            {/* First Column: Last Run and Regular Avg (takes 2/3 of the space) */}
            <div className=" flex flex-col justify-end text-center">

              <div className='flex justify-center'>
              {runDate && (
                <span className="text-xs">
                  Last Run:{" "}
                  {/* Addition is for a dumb est timezone correction */}
                  {new Date(new Date(runDate).getTime() + 46400000).toLocaleDateString(
                    "en-US",
                    {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    }
                  )}
                </span>
              )}
              </div>
                
                {/* The comp avg price type selection: */}
                <div className="flex font-bold text-sm items-center justify-center flex-grow">
                <FormControl
                  variant="standard"
                  sx={{
                    display: "flex",
                    alignItems: "flex-start", // Align FormControl contents to the top
                    height: "100%", // Ensure FormControl spans full height for alignment
                    "& .MuiInput-underline:before": {
                      borderBottom: "none", // Remove underline by default
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottom: "2px solid black", // Show underline on hover
                    },
                    "& .MuiInput-underline:after": {
                      borderBottom: "2px solid #3f51b5", // Underline when focused
                    },
                    "&.Mui-disabled": {
                      color: "black !important", // Ensure text remains black when disabled
                    },
                  }}
                >
                  <InputLabel
                    id="comp-avg-select-label"
                    sx={{
                    }}
                  >
                    Display
                  </InputLabel>
                  <Select
                    labelId="comp-avg-select-label"
                    className="mt-0 !important py-0 !important"
                    value={selectedKey}
                    onChange={handleSelectChange}
                    disabled={value === 2}
                    renderValue={(selected) => {
                      if (selected === "selectedComps" && isNaN(compAvg.selectedComps)) {
                        return "Select Comps";
                      }
                      if (selected === "selectedComps") {
                        return "Selected Avg";
                      }
                      if (selected === "top5") {
                        return "Top Five Avg";
                      }
                      if (selected === "offerValue") {
                        return memoizedOfferAmount || isEditing ? "Offer Value" : "Add Offer";
                      }
                      return selected; // Fallback for any other potential values
                    }}
                    sx={{
                      "&.Mui-focused": {
                        backgroundColor: "transparent", // Ensure no background when focused
                      },
                      "& .MuiSelect-select:focus": {
                        backgroundColor: "transparent", // Transparent background inside the select wrapper
                      },
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                      "&:focus": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <MenuItem value="selectedComps">Selected Avg</MenuItem>
                    <MenuItem value="top5">Top Five Avg</MenuItem>
                    {memoizedOfferAmount || isEditing ? (
                      <MenuItem value="offerValue">Offer Value</MenuItem>
                    ) : (
                      <MenuItem value="offerValue">Add Offer</MenuItem>
                    )}
                  </Select>


                </FormControl>

            {/* avg price and offer display with AV toggle */}
            <div className="flex">
              {selectedKey === "selectedComps" && !isNaN(compAvg.selectedComps) && (
                <div className="flex flex-col justify-start relative w-full">
                  {/* Placeholder label */}
                  <span
                    className="w-full"
                    style={{
                      position: "absolute",
                      bottom: "-4px", // Adjust to sit just above the input field
                      left: "50%", // Center horizontally
                      transform: "translateX(-37%)",
                      fontSize: "11px", // Small text
                      pointerEvents: "none", // Avoid interfering with clicks
                    }}
                  >
                    <span className={`font-normal ${compAvg.selectedComps === 'None selected' ? 'hidden' : ''}`}>
                      {Math.round(compAvg.selectedComps * getRar).toLocaleString() + ` `}
                      AV
                    </span>
                  </span>

                  <TextField
                    value={
                      typeof compAvg.selectedComps === "string"
                        ? compAvg.selectedComps
                        : showAVs
                        ? Math.round(compAvg.selectedComps * getRar).toLocaleString()
                        : Math.round(compAvg.selectedComps).toLocaleString()
                    }
                    variant="outlined"
                    className="group"
                    InputProps={{
                      readOnly: true, // Makes the field non-editable
                      startAdornment: (
                        <InputAdornment
                          position="end"
                          className={`relative flex ${compAvg.selectedComps === 'None selected' ? 'items-center': 'items-start'}`}
                          sx={{
                            height: "32px", // Match the height of the input field
                            marginTop: "0px", // Align adornment with the input
                            marginBottom: "0px", // Keep consistent alignment
                            alignSelf: "flex-end", // Ensure adornment stays in line with input content
                          }}
                        >
                          <div className="flex items-start relative">
                            {showAVs ? <span className="z-10">AV</span> : <span className="z-10">$</span>}
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="off"
                    sx={{
                      margin: 0, // Remove unnecessary margins
                      padding: 0, // Remove unnecessary padding
                      "& .MuiInputBase-root": {
                        display: "flex",
                        height: "32px", // Standardize height
                        maxWidth: "135px",
                        alignItems: compAvg.selectedComps === 'None selected' ? "flex" : "flex-start", // Conditional alignment
                        padding: "0px", // Ensure no additional padding
                      },
                      "& input": {
                        textAlign: "right",
                        padding: "0px", // Ensure no extra padding
                        minWidth: "40px", // Minimum width for small numbers
                        width: `${
                          typeof compAvg.selectedComps === "string"
                            ? 110
                            : showAVs
                            ? Math.max(Math.round(compAvg.selectedComps * getRar).toString().length * 10, 40)
                            : Math.max(Math.round(compAvg.selectedComps).toString().length * 10, 40)
                        }px`, // Dynamically calculate width
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none", // Remove border outline
                      },
                    }}
                  >
                  </TextField>
                </div>
              )}
              {/* Experimental seperate way to do the AV display */}
              {selectedKey === "top5" && (
                <div className="flex flex-col justify-start relative w-full">
                  {/* Placeholder label */}
                    <span
                    className="w-full"
                    style={{
                      position: "absolute",
                      bottom: "-4px", // Adjust to sit just above the input field
                      left: "50%", // Center horizontally
                      transform: "translateX(-37%)",
                      fontSize: "11px", // Small text
                      pointerEvents: "none", // Avoid interfering with clicks
                    }}
                  >
                    <span className={`font-normal ${compAvg.top5 === 'None selected' ? 'hidden' : ''}`}>
                      {Math.round(compAvg.top5 * getRar).toLocaleString() + ` `}
                      AV
                    </span>
                  </span>

                  <TextField
                    value={
                      typeof compAvg.top5 === "string"
                        ? compAvg.top5
                        : showAVs
                        ? Math.round(compAvg.top5 * getRar).toLocaleString()
                        : Math.round(compAvg.top5).toLocaleString()
                    }
                    variant="outlined"
                    className="group"
                    InputProps={{
                      readOnly: true, // Makes the field non-editable
                      startAdornment: (
                        <InputAdornment
                          position="end"
                          className={`relative flex ${compAvg.top5 === 'None selected' ? 'items-center' : 'items-start'}`}
                          sx={{
                            height: "32px",
                            marginTop: "0px",
                            marginBottom: "0px",
                            alignSelf: "flex-end",
                          }}
                        >
                          <div className="flex items-center relative">
                            {<span className="z-10">$</span>}
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="off"
                    sx={{
                      margin: 0, // Remove unnecessary margins
                      padding: 0, // Remove unnecessary padding
                      "& .MuiInputBase-root": {
                        display: "flex",
                        height: "32px", // Standardize height
                        maxWidth: "120px",
                        alignItems: compAvg.top5 === 'None selected' ? "flex" : "flex-start", // Conditional alignment
                        padding: "0px", // Ensure no additional padding
                      },
                      "& input": {
                        textAlign: "right",
                        padding: "0px", // Ensure no extra padding
                        minWidth: "40px", // Minimum width for small numbers
                        width: `${
                          typeof compAvg.top5 === "string"
                            ? 120
                            : showAVs
                            ? Math.max(Math.round(compAvg.top5 * getRar).toString().length * 10, 40)
                            : Math.max(Math.round(compAvg.top5).toString().length * 10, 40)
                        }px`, // Dynamically calculate width
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none", // Remove border outline
                      },
                    }}
                  />
                </div>
              )}
              {/* This offer input should be brought into its own component probbaly eventually */}
              {selectedKey === "offerValue" && (
                <div className="flex flex-col justify-start relative w-full">
                  <Tooltip
                    title={`Offer AV: ${Math.round(memoizedOfferAmount * getRar).toLocaleString()}`}
                    placement="bottom"
                    open={isTooltipOpen}
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -10], // Adjusts tooltip positioning closer to the input field
                          },
                        },
                      ],
                    }}
                  >
                    <TextField
                      value={
                        showAVs
                          ? Math.round(memoizedOfferAmount * getRar).toLocaleString()
                          : memoizedOfferAmount.toLocaleString()
                      }
                      onFocus={() => setIsFocused(true)} // Open tooltip on focus
                      onBlur={() => setIsFocused(false)} // Close tooltip on blur
                      onMouseEnter={() => setIsHovered(true)} // Open tooltip on hover
                      onMouseLeave={() => setIsHovered(false)} // Close tooltip when hover ends
                      onInput={() => {
                        if (showAVs) {
                          toast.error("You must toggle to $ to edit offer value");
                          setShowAVs(false);
                        }
                      }}
                      onChange={(e) => {
                        if (!showAVs) {
                          handleChange(e); // Handle input changes in dollar mode
                        }
                      }}
                      variant="outlined"
                      placeholder="Offer"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="end"
                            className="mr-1 relative flex items-center cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault(); // Prevent default behavior
                              e.stopPropagation(); // Stop event propagation
                              setShowAVs(!showAVs); // Toggle AV/$ mode
                            }}
                          >
                            <div className="flex items-center relative">
                              {showAVs ? <span className="z-10">AV</span> : <span className="z-10">$</span>}
                            </div>
                          </InputAdornment>
                        ),
                        inputComponent: NumberFormatCustom,
                      }}
                      autoComplete="off"
                      sx={{
                        "& .MuiInputBase-root": {
                          padding: "0px 4px",
                          height: "32px",
                          maxWidth: "135px",
                          border: "1px solid transparent",
                          transition: "border-color 0.2s ease",
                          "&:hover": {
                            borderColor: "#000",
                          },
                        },
                        "& input": {
                          textAlign: "right",
                          padding: "0",
                          minWidth: "40px",
                          width: `${
                            showAVs
                              ? Math.max(Math.round(memoizedOfferAmount * getRar).toString().length * 10, 40)
                              : Math.max(memoizedOfferAmount.toString().length * 10, 40)
                          }px`,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          
                {/* Only display the % if its less than 90% red. */}
                {pctAdj > -0.6 
                ? selectedKey !== "offerValue" 
                  ? (
                    <div 
                      className="flex items-center justify-center h-full"
                      style={{
                        height: "100%", // Ensure height spans parent container
                      }}
                    >
                      <span
                        className={`text-xs ml-1 ${pctAdjClass}`}
                        style={{
                          display: "flex", // Flex ensures proper alignment
                          alignItems: "center",
                          justifyContent: "center", // Align within span if needed
                        }}
                      >
                        ({(pctAdj * 100).toFixed(1)}%)
                      </span>
                    </div>
                  )
                  : offerPctAdj > -0.6 
                  ? (
                    <div 
                      className="flex items-center justify-center h-full"
                      style={{
                        height: "100%", // Ensure consistent height
                      }}
                    >
                      <span
                        className={`text-xs ${offerPctAdjClass}`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center", // Center text in span
                        }}
                      >
                        ({(offerPctAdj * 100).toFixed(1)}%)
                      </span>
                    </div>
                  )
                  : (
                    <span 
                      className='text-xs ml-1 w-[44px]'
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {/* Empty space for alignment */}
                    </span>
                  )
                : (
                  <span 
                    className='text-xs ml-1 w-[18px]'
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {/* Empty space for alignment */}
                  </span>
                )
              }

              </div>
              
            </div>
          </div>

            {/* Section 3: Comp navigation */}
            <div className="w-1/3 flex justify-end text-right">

                {/* Eval date and adjustments */}
              <div className='text-sm mr-2'>
                {/* could move this to its own component if you start doing more with the adj price */}
                {/* Eval date popover on top of the adjustments */}
                <div className='flex justify-end items-center text-xs'>
                  <span>Eval Date: {formattedEvalDate}</span>
                  <InfoOutlinedIcon 
                    className='cursor-pointer ml-1' 
                    fontSize='inherit'
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  />
                  <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <Box p={2}>
                      <div className='flex flex-col'>
                      <span>RAR: {getRar}</span>
                      <span>EQ: {getEq}</span>
                      </div>
                    </Box>
                  </Popover>
                </div>
              <AdjustmentsPopover adjustments={getAdjustments} />
              </div>

              <CompNavigation
                globalCompRef={globalCompRef}
                handleUpdateStateCallback={handleUpdateStateCallback}
              />
            </div>
          </div>

      </div>
    );
};


export default TableCustomToolbar;