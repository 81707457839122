import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useLocation } from 'react-router-dom';
import { useCompStore, usePersistedDataStore } from '../store.js';
import DownloadButton from './DownloadButton.js';
import { showModalAndWaitForSelection } from '../../common/ShowModalAndWaitForSelection.js'; // you should be using this instead of ur manual jury rigged version in fetchcomps.
import axiosInstance from '../../axiosConfig.js';
import SummaryCaseReviewTable from './SummaryCaseReviewTable.js';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import HomeWork from '@mui/icons-material/HomeWork';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalanceOutlined';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import GavelIcon from '@mui/icons-material/Gavel';



// This route calls get sales data every time you re-render it
// You should 
function ReviewSettlements({isAdmin=false, handleUpdateStateCallback, negotiationCases, filterType, setFilterType, filteredNegotiationCases}) {
  const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj);
  const setNegotiationObj = usePersistedDataStore((state) => state.setNegotiationObj);
  const [doneUpdating, setDoneUpdating] = useState(false);
  const getCompSheet = usePersistedDataStore((state) => state.compSheet)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const comp = parseInt(queryParams.get('comp')) - 1 || 0 ; // Subtract 1
  const taxYear = queryParams.get('TaxYear') || getNegotiationObj.TaxYear; // updating taxyear updates whole obj. Analyze refactors to see if performance issue in future.
  const courtDate = queryParams.get('CourtDate') || 'All';
  const MuniCode = queryParams.get('MuniCode') || 'All';
  const municipalityString = queryParams.get('municipalityString') || 'All';
  const hasFetchedSales = useRef(false); // Track if sales data has been fetched

  const totalIFMV = useMemo(() => {
    return negotiationCases.reduce((acc, item) => {
      if (item && item.IFMVTown) {
        return acc + item.IFMVTown;
      }
      return acc;
    }, 0);
  }, []);
  
  // Parsed out from the update function
    // Function to fetch sales data
    // const fetchSalesData = useCallback(async () => {
    //   if (hasFetchedSales.current) return; // Skip if already fetched
    //   console.log('fetching sales data');
    //   try {
    //     console.log(negotiationCases)
    //     const casesToMap = negotiationCases;
    //     const uids = casesToMap.map((item) => item.PID);
  
    //     const salesResponse = await axiosInstance.post('/get_sales_data', {
    //       TaxYear: taxYear,
    //       MuniCode: MuniCode,
    //       uids: uids,
    //     });
  
    //     const salesData = salesResponse.data;
  
    //     // Integrate the sales data into negotiation cases
    //     const updatedCases = casesToMap.map((caseItem) => {
    //       const saleInfo = salesData.find((sale) => sale.pid === caseItem.PID);
    //       return saleInfo
    //         ? { ...caseItem, ...saleInfo }
    //         : caseItem;
    //     });
  
    //     setNegotiationObj({
    //       ...getNegotiationObj, // Spread other properties to retain them
    //       cases: updatedCases,   // Replace the cases key with updated values
    //     });
    //     hasFetchedSales.current = true; // Mark sales data as fetched
    //     toast.success('Sales data updated successfully');
    //   } catch (error) {
    //     console.error(error);
    //     toast.error('Error fetching sales data');
    //   }
    // }, [negotiationCases, taxYear, MuniCode]);

  const summaryData = {
    totalPropertyValue: 3855738615,
    totalPropertyTax: 36558951,
    annualTrend: 0.04545,
    propertiesGrieved: totalIFMV,
  }

    let runningSetlement = 0; // local var to tally your settlement value.
    let runningIFMV = 0; // var to tally your IFMV's (as cases are settled to calc the % reduct)
    let runningNC = 0;
  const fileName = `${MuniCode}_${taxYear}${courtDate === 'All' ? '' : `_${courtDate}`}`;
  const [selectionModel, setSelectionModel] = React.useState([]);


    // For the download
    const keys = ['PID', 'Address', 'PropertyTown', 'PropertyZip', 'Name', 'SCARIndexNumber','AssessmentTown', 'IFMVTown', 'RunDate', 'SalePrice', 'SaleDate', 'RecentSale', 'EstimatedTaxes', 'SubjectMarketValue', 'OfferValue', 'RAR', 'RequestedAssessment', 'PctReduction', 'CaseNotes', 'RepID', 'settled'];

    // LETS UPDATE this to be memoized not 
    // useEffect(() => {
    //   // console.log('useEffect triggered');
    //   updateNegotiation(true); // Consolidated function call
    // }, []);

    // This is sort of dumb, but currently it is what puts your needed vals into the negotiation obj for summary table.
    // This is also very obviously inefficient and is re-running stuff that you dond't need to
    const updateNegotiation = useCallback(async (force=false) => {
      // Added a forced re-run on component mount to update your offer values
      // console.log('running the update');
      try {
        let updatedCases = negotiationCases;
         // Check if all cases already have RequestedAssessment keys - can do a diff check if have other things you need to update too.
        const casesToMap = negotiationCases;
        const allCasesHaveRequestedAssessment = casesToMap.every((caseItem) => caseItem && caseItem.RequestedAssessment !== undefined);
        if (allCasesHaveRequestedAssessment && !force) {
          // console.log('All cases already have RequestedAssessment keys. No update needed.');
          setDoneUpdating(true);
          return; // Exit early if no update is necessary
        }
    
        // Step 1: Fetch sales data if not already fetched
        if (!hasFetchedSales.current) {
          console.log('fetching sales data');
          
          const uids = negotiationCases.map((item) => item.PID);
          const salesResponse = await axiosInstance.post('/get_sales_data', {
            TaxYear: taxYear,
            MuniCode: MuniCode,
            uids: uids,
          });
          console.log(salesResponse)
    
          const salesData = salesResponse.data.map((sale) => ({
            pid: sale.pid,
            SaleDate: sale.saleDate,
            SalePrice: sale.salePrice,
            RecentSale: sale.recentSale,
          }));

          updatedCases = negotiationCases.map((caseItem) => {
            const saleInfo = salesData.find((sale) => sale.pid === caseItem.PID);
            if (saleInfo) {
              const { pid, ...saleInfoWithoutPid } = saleInfo; // Exclude `pid` from the final object
              return { ...caseItem, ...saleInfoWithoutPid };
            }
            return caseItem;
          });
    
          hasFetchedSales.current = true; // Mark sales data as fetched
          console.log('the sales data from update')
        }
    
        // Step 2: Update cases with requested assessments
        updatedCases = updatedCases.map((item, index) => {
          if (item && item.RequestedAssessment === undefined && item.type !== null) {
            const compSheetItem = getCompSheet[index];
            if (compSheetItem && compSheetItem.PropertyAssessment) {
              let requestedAssessment = item.SubjectMarketValue * compSheetItem.RAR[0];
              if (item.OfferValue) {
                const offeredAssessment = item.OfferValue * compSheetItem.RAR[0];
                requestedAssessment = offeredAssessment;
              }
    
              if (
                compSheetItem.IFMV[0] > 450000 &&
                requestedAssessment < 0.75 * compSheetItem.PropertyAssessment[0]
              ) {
                item.SubjectMarketValue = 0.75 * compSheetItem.IFMV[0];
                requestedAssessment = 0.75 * compSheetItem.PropertyAssessment[0];
              }
    
              const percentReduction =
                (compSheetItem.PropertyAssessment[0] - requestedAssessment) /
                compSheetItem.PropertyAssessment[0];
    
              return {
                ...item,
                Address: compSheetItem.Address[0],
                PropertyAssessment: compSheetItem.PropertyAssessment[0],
                RAR: compSheetItem.RAR[0],
                RequestedAssessment: parseInt(requestedAssessment, 10),
                PctReduction: percentReduction,
                PropertyTown: compSheetItem.Town[0],
                PropertyZip: compSheetItem.ZipCode[0],

              };
            }
          }
          return item; // Return original item if no updates needed
        });
    
        // Step 3: Update the negotiation object once
        setNegotiationObj({
          ...getNegotiationObj, // Retain other properties
          cases: updatedCases,   // Replace cases with updated data
        });
    
      } catch (error) {
        console.error(error);
        toast.error('Error updating negotiation object');
      }
    }, [negotiationCases, taxYear, MuniCode, axiosInstance, getCompSheet]);
    
    const handleCellClick = useCallback((selection) => {
      console.log("Row ID:", selection);
      setSelectionModel(selection);
    }, [selectionModel]);

    return (
    // On this page, if there's no settlements (or even if there are) - have an option to "save review" - this will save the comp order,
    // And save any min/max offers (and settlements)
<div className="h-full flex flex-col">
  {/* Summary Stats Section */}
  <div className="flex-shrink-0">
    <div className="flex flex-col justify-center overflow-hidden">
      <Typography variant="h5" align="center" gutterBottom>
        {municipalityString} Summary Stats
      </Typography>

        <Grid container spacing={3} justifyContent="center" className='mb-1'>
          {/* UPDATE MAX WIDTH TO 245 px if u wanna keep tight */}
          
          {/* Total Property Value Card */}
          <Grid item xs={12} sm={3} >
            <Card elevation={6} sx={{ borderRadius: 2, boxShadow: 1 }}>
              <CardContent>
                {/* Header: Left-aligned title */}
                <Typography variant="h6" color="textSecondary" sx={{ textAlign: 'left'}}>
                  Total Property Value
                </Typography>

                {/* Body: Icon and information in flex layout */}
                <Grid container alignItems="center" spacing={2}>
                  {/* Property Value Information */}
                  <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h5" component="div">
                      ${summaryData?.totalPropertyValue.toLocaleString()}
                    </Typography>
                  </Grid>

                  {/* Icon */}
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <MapsHomeWorkOutlinedIcon sx={{ fontSize: '3rem', color: 'primary.main' }} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Total Property Tax Card */}
          <Grid item xs={12} sm={3}>
            <Card elevation={6} sx={{ borderRadius: 2, boxShadow: 1 }}>
              <CardContent>
                {/* Header: Full-width title */}
                <Typography variant="h6" color="textSecondary" sx={{ textAlign: 'left'}}>
                  Total Property Tax
                </Typography>

                {/* Body: Icon and information in flex layout */}
                <Grid container alignItems="center" spacing={2}>
                  {/* Property Tax Information */}
                  <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h5" component="div">
                      ${summaryData?.totalPropertyTax.toLocaleString()}
                    </Typography>
                  </Grid>

                  {/* Icon */}
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <AccountBalanceIcon sx={{ fontSize: '3rem', color: 'primary.main' }} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Annual Time Trend Card */}
          <Grid item xs={12} sm={3}>
            <Card elevation={6} sx={{ borderRadius: 2, boxShadow: 1 }}>
              <CardContent>
                {/* Header: Full-width title */}
                <Typography variant="h6" color="textSecondary" sx={{ textAlign: 'left' }}>
                  {taxYear
                    ? `'${taxYear.toString().slice(2, 4)} Time Trend`
                    : 'Time Trend'}
                </Typography>

                {/* Body: Icon and information in flex layout */}
                <Grid container alignItems="center" spacing={2}>
                  {/* Trend Information */}
                  <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h5" component="div">
                      {(summaryData?.annualTrend * 100).toFixed(2)}%
                    </Typography>
                  </Grid>

                  {/* Icon */}
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {summaryData?.annualTrend > 0 ? (
                      <TrendingUpIcon sx={{ fontSize: '3rem', color: 'success.main' }} />
                    ) : (
                      <TrendingDownIcon sx={{ fontSize: '3rem', color: 'error.main' }} />
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>


          {/* Properties grieved (display potential rev shortfall) Card */}
          <Grid item xs={12} sm={3}>
            <Card elevation={6} sx={{ borderRadius: 2, boxShadow: 1 }}>
              <CardContent>
                {/* Header: Left-aligned title */}
                <Typography variant="h6" color="textSecondary" sx={{ textAlign: 'left', }}>
                  Properties Grieved
                </Typography>

                {/* Body: Icon and information in flex layout */}
                <Grid container alignItems="center" spacing={2}>
                  {/* Grievance Amount Information */}
                  <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h5" component="div">
                      ${summaryData?.propertiesGrieved.toLocaleString()}
                    </Typography>
                  </Grid>

                  {/* Icon */}
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <GavelIcon sx={{ fontSize: '3rem', color: 'primary.main' }} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>


        </Grid>
        </div>
  </div>


  <div className="flex flex-col flex-grow h-0">
    {/* Filtering and Download Div */}
    <div className="flex justify-between mb-1 mt-2 flex-shrink-0">
      <div className="flex">
        <FormControl>
          <InputLabel sx={{ zIndex: '1 !important', background: 'transparent' }}>
            Case Filter
          </InputLabel>
          <Select
            sx={{
              minWidth: 120,
              '& .MuiSelect-select': {
                py: 1,
              },
              '& .MuiInputBase-input': {
                py: 1,
              },
            }}
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
            label="Filter Type"
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="unreviewed">Unreviewed</MenuItem>
            <MenuItem value="open">Unsettled</MenuItem>
            <MenuItem value="scar">SCAR Filed</MenuItem>
            <MenuItem value="settled">Settled</MenuItem>
          </Select>
        </FormControl>
      </div>
      <DownloadButton
        caseData={negotiationCases}
        fileName={fileName}
        selectedRows={selectionModel}
        keys={keys}
      />
    </div>

    {/* Case Review Table */}
    
      <SummaryCaseReviewTable
        handleCellClick={handleCellClick}
        selectionModel={selectionModel}
        negotiationCases={negotiationCases}
        filteredNegotiationCases={filteredNegotiationCases}
        handleUpdateStateCallback={handleUpdateStateCallback}
        updateNegotiation={updateNegotiation}
        doneUpdating={doneUpdating}
      />
  </div>
</div>
  );
} export default ReviewSettlements
