import React, { memo } from 'react';
import AddIcon from '@mui/icons-material/Add';

const ConversationHistory = ({ handleSetConversation, handleStartNewConversation, conversationHistory }) => {
    // Log your convo array here

    return (
        <div>
            {/* <h2 className="text-xl font-semibold mb-8">Proprietary Chat</h2> */}
            <div className="flex-col rounded-lg">
                <div
                    className="flex justify-center hover:bg-gray-300 hover:cursor-pointer py-1 rounded-lg"
                    onClick={handleStartNewConversation}
                >
                    New Chat
                    <AddIcon />
                </div>

                {conversationHistory
                    .slice() // Create a shallow copy of the array
                    .reverse() // Reverse the order of the array
                    .map((conversation) => {
                    return (
                        <div
                            className="flex pl-2 py-1 hover:bg-gray-300 hover:cursor-pointer mt-3 rounded-lg"
                            key={conversation.id}
                            onClick={() => handleSetConversation(conversation.id)}
                        >
                            {conversation.name}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

// Custom comparison function for React.memo
const areEqual = (prevProps, nextProps) => {
    // Check if the length of the conversationHistory array has changed
    if (prevProps.conversationHistory.length !== nextProps.conversationHistory.length) {
        return false;
    }

    // Check if the IDs or names of the conversations have changed
    for (let i = 0; i < prevProps.conversationHistory.length; i++) {
        if (
            prevProps.conversationHistory[i].id !== nextProps.conversationHistory[i].id ||
            prevProps.conversationHistory[i].name !== nextProps.conversationHistory[i].name
        ) {
            return false;
        }
    }

    // Prevent re-render if only the `messages` property changes
    return true;
};

export default ConversationHistory;
