import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import propriety from '../images/propriety-32.png'
import Switch from '@mui/material/Switch';
import ProprietyLogo from '../common/ProprietyLogo';
import {useAuth} from './AuthContextProvider'
import { NavLink, useNavigate, useLocation} from 'react-router-dom'

// home (as default)
// Add in the property analysis & Graphs when user is signed in

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  // Build this in lightmode for now, eventually we'll abstract this to localstorage
  const [darkMode, setDarkMode] = React.useState(false);
  const {user, logout, loading} = useAuth();
  const navigate = useNavigate();
  const location = useLocation(); 
  // const pages = ['Home', 'Pricing', 'Blog'];
  // update the pages display based on if the user state is signed in:
  // const pages = user ? ['Home', 'Analysis', 'Graphs', 'Pricing'] : ['Home', 'Pricing'];
  const pages = user ? [{page:'Home', route:'/'}, {page:'Analysis',route:'/table'}, {page:'Graphs',route:'/graph'}] : [{page:'Home',route:'/'}];



  const userSettings = [
    {
      label: 'Profile',
      action: () => console.log('/profile'),
      disabled: true,
    },
    // {
    //   label: 'Account',
    //   action: () => console.log('/account'),
    // },
    // {
    //   label: 'Dashboard',
    //   action: () => console.log('/dashboard'),
    // },
    {
      label: 'Logout',
      action: () => {
        logout();
      },
    },
  ];

  const handleToggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (route) => {
    setAnchorElNav(null);
    if(route){
    navigate(route)
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static"
    sx={{
      backgroundColor: darkMode ? '#333' : 'white',
      boxShadow: 3,
      color: darkMode ? 'white' : 'black',
    }}>
      <Container maxWidth="xl" className='z-[999]'>
      <Toolbar 
        disableGutters 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', // Ensures vertical alignment
          height: '100%',       // Matches the AppBar's height
        }} 
        className="justify-between"
      >
        {/* Propriety Logo */}
        <div className="hidden md:flex items-center h-full">
          <ProprietyLogo style={{ height: '100%' }} /> {/* Ensure it fills the parent container */}
        </div>

        {/* Mobile Menu */}
        <Box className="flex md:hidden items-center">
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={() => handleCloseNavMenu()}
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            {pages.map(({ page, route }) => (
              <MenuItem key={page} onClick={() => handleCloseNavMenu(route)}>
                <Typography
                  sx={{
                    textAlign: 'center',
                    color: location.pathname === route ? 'primary.main' : 'black', // Set primary color if active
                  }}
                >
                  {page}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>

        {/* Page Links */}
        <Box 
          sx={{ 
            flexGrow: 1, 
            display: 'flex', 
            alignItems: 'center', // Centers the links vertically
            height: '100%', 
          }} 
          className="ml-4 hidden md:flex"
        >
          {pages.map(({ page, route }) => (
            <Button
              key={page}
              disableRipple
              onClick={() => handleCloseNavMenu(route)}
              sx={{
                mx: 1, // Spacing between buttons
                color: location.pathname === route ? 'primary.main' : 'black', // Active color
                padding: '6px 12px', // Consistent padding
                '&:hover': {
                  color: 'primary.main',
                  backgroundColor: 'transparent',
                },
              }}
            >
              {page}
            </Button>
          ))}
        </Box>



          {/* User menu */}
          {user && 
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
             {userSettings.map((setting) => (
                  <MenuItem
                    key={setting.label}
                    onClick={() => {
                      setting.action(); // Execute the action
                      handleCloseUserMenu(); // Ensure the menu closes
                    }}
                    disabled={setting.disabled}
                    >
                    <Typography sx={{ textAlign: 'center' }}>{setting.label}</Typography>
                  </MenuItem>
              ))}
            </Menu>
          </Box>
          }

          {/* Dark mode toggle switch */}
          {/* <Box sx={{ ml: 'auto' }}>
            <Switch checked={darkMode} onChange={handleToggleDarkMode} />
          </Box> */}

          {!user && !loading &&
          <Tooltip title="Sign In">
            <Button
              component={NavLink}
              to="/signin"
              variant='contained'
            >
              Sign In
            </Button>
          </Tooltip>
          }

        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;