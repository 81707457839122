import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useLocation } from 'react-router-dom';
import CaseReview from "./CaseReview";
import {
  useLocalDataStore,
  useAdminSettingsStore,
  usePersistedDataStore,
} from "../store";
import CompDashboard from "./CompDashboard";
import ReviewSettlements from "./ReviewSettlements";
import _ from "lodash";
import SettingsWidget from "../SettingsWidget";
import axiosInstance from '../../axiosConfig';
import MuniSelection from "./MuniSelection";
import Button from '@mui/material/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';

function Home({
  setLoading,
  initializationFetch,
  weightWidget,
  setWeightWidget,
  isAdmin,
  nyDataObj,
  yearDropdownOptions,
  compStreamObject,
  globalCompRef,
}) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const getCompSheet = usePersistedDataStore((state) => state.compSheet);
  const [reOrderedComps, setReOrderedComps] = useState({});
  const getReviewPage = useLocalDataStore((state)=> state.reviewPage)
  // at the end of your fethc, do you update any of these sttes?
  const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj);
  const getOptimizedComps = usePersistedDataStore((state) => state.optimizedComps);
  const setCompSheet = usePersistedDataStore((state) => state.setCompSheet);
  const setOptimizedComps = usePersistedDataStore((state) => state.setOptimizedComps);

  // i believe filtering in this way will breka things within ur app.
  // Specifically if we reference getcompsheet anywhere, and potentially your refs too
  const getFilterIndexes = usePersistedDataStore((state) => state.filterIndexes);
  const setFilterIndexes = usePersistedDataStore((state) => state.setFilterIndexes);

  // This memoization means it WILL NOT RERENDER IF YOU UPDATE THE BROADER OBJECT THAT DOESNT AFFEC THESE INDICES
  // this means you should be able to greatly simply your streaming function to just straight up update the object
  // It will only cause an issue if it updates the specific one the user is on, which is a very small edge case imo
  // And clone deep them, so that they are NOT considered dependencies to the broader array object (of getnegotiationObj)
  const comp = parseInt(queryParams.get('comp')) - 1 || 0; // Subtract 1
  // Generate a unique key based on the `comp` value
  // const resetKey = useMemo(() => comp || "default", [comp]);

  // This string of memoizsation needs to be revisited at some point.
  const memoizedNegotiationObj = useMemo(() => {
    return cloneDeep(getNegotiationObj.cases[comp]);
  }, [comp, getNegotiationObj.cases[comp]]);

  // By using stringify you can do a deep comparison check on the object to see if it is updated
  const memoizedFullNegotiationCases = useMemo(() => {
    return cloneDeep(getNegotiationObj.cases);
  }, [JSON.stringify(getNegotiationObj.cases)]);

  const memoizedNegotiationFiltered = useMemo(() => {
    if(getFilterIndexes){
    return cloneDeep(getNegotiationObj.cases.filter((_, index) => getFilterIndexes.includes(index)));
    }
    return memoizedFullNegotiationCases;
  }, [memoizedFullNegotiationCases,getFilterIndexes]);

  const memoizedSavedComps = useMemo(() => 
    cloneDeep(Object.values(getNegotiationObj.cases[comp]?.Comps || {})), [comp, getNegotiationObj.cases[comp]?.Comps]);

  // Idt this is properly updating on changes
  const memoizedComps = useMemo(() => {
    // console.log('Recomputing memoizedComps for comp:', comp);
    return cloneDeep(getCompSheet[comp]);
  }, [comp, JSON.stringify(getCompSheet[comp])]);
  
  // Memoize optimized comps for the specific comp index
  const memoizedOptimizedComps = useMemo(() => 
    cloneDeep(getOptimizedComps[comp]), [comp, getOptimizedComps]);

  const [upcomingCourtDates, setUpcomingCourtDates] = useState([]);
  const [updatedArray, setUpdatedArray] = useState([]);

  const memoizedIsCompUpdated = useMemo(() => {
    // Check if updatedArray has no elements or if updatedArray[comp] exists and equals `true`
    return updatedArray.length === 0 || updatedArray[comp] === true;
  }, [updatedArray, comp]);
  
  const [streaming, setStreaming] = useState(false);


  const handleSetStreaming = useCallback((value) => {
    setStreaming(value);
  }, []);
  
  // const memoizedUpdatedArray = useMemo(() => updatedArray, [updatedArray]);
  // Memoize the fetchCourtDates function to prevent it from being recreated on each render
  const fetchCourtDates = useCallback(async () => {
    try {
      const courtResponse = await axiosInstance.get('/load_court_dates');
      const courtDates = Object.keys(courtResponse.data);
      courtDates.unshift(''); // Add an empty string at the start if necessary
      setUpcomingCourtDates(courtDates);
    } catch (error) {
      toast.error('Error fetching court dates:', error)
      console.error('Error fetching court dates:', error);
    }
  }, []);
  
  const lastFilterRef = useRef(null);

  // EXPERIMENTING WITH PUTTING THE FILTERING HERE
      // THIS SEEMS TO BE WORKING???? -- does it error IF its currently running?
      // You should just load in the filter type from localstorage i think instead of putting this up home
      // Send filter into its own index
      const [filterType, setFilterType] = useState('all'); // State for the selected filter

      const filterIndexes = useCallback((type) => {
        if (type === 'all') {
            // If the filter type is "all", set the filter indexes to undefined
            setFilterIndexes(undefined);
            lastFilterRef.current = undefined;
            return undefined;
        }
    
        const cases = cloneDeep(getNegotiationObj.cases);
    
        // Create an array of indexes that meet the criteria
        const indexesToKeep = cases.reduce((indexes, currentCase, index) => {
            let keep = false;
    
            if (type === 'open') { // "unsettled"
                const scarStageExists = cases.some(c => c.SCARFiled === 1);
                if (scarStageExists) {
                    keep = !['S', 'SD', 'ST', 'W', 'NM', 'AH'].includes(currentCase.SCARDeterminationAction) && currentCase.SCARFiled === 1;
                } else {
                    keep = !['S', 'SD', 'ST', 'W', 'NM', 'AH'].includes(currentCase.SCARDeterminationAction);
                }
            } else if (type === 'settled') {
                keep = ['S', 'SD', 'ST', 'W', 'NM', 'AH'].includes(currentCase.SCARDeterminationAction);
            } else if (type === 'unreviewed') {
              // Redo this, it should only be kept if Object.keys(currentCase.Comps).length>0
              console.log(currentCase)
              keep = !currentCase.Comps || (typeof currentCase.Comps === 'object' && Object.keys(currentCase.Comps).length === 0);
            // } else if (type === 'noMarketValue') {

                // keep = !currentCase.SubjectMarketValue;
            } else if (type === 'scar') {
                keep = currentCase.RepID !== "" && currentCase.RepID != null || currentCase.SCARFiled === 1;
            }
    
            // Add index to the array if it meets the criteria
            if (keep) {
                indexes.push(index);
            }
    
            return indexes;
        }, []);
    
        if (_.isEqual(indexesToKeep, lastFilterRef.current)) {
            return indexesToKeep;
        }
    
        setFilterIndexes(indexesToKeep);
        lastFilterRef.current = indexesToKeep;
    
        return indexesToKeep; // or use it as needed
    }, [getNegotiationObj.cases]);

      const handleFilterChange = useCallback((type) => {
        // console.log('updated filter',type)
        setFilterType(type);
        filterIndexes(type);
      }, [filterIndexes]);
  
      // console.log(getCompSheet)
  // Use your ref objects from streamed responses to update state when applicable
  // Invoked on comp change.
  // console.log('updated array:', updatedArray)

  // In this, true means the comp has been updated and set in state (unlike stream ref, whcih is just that the other ref is updated.)
  const updatedArrayRef = useRef([]);

  // Need to add a state that prevents this from firing IF it is already streaming.
  const handleUpdateStateCallback = useCallback((resetState) => {
    // console.log('update state callback invoked')
    // console.log('running state update current updated array:', updatedArray)
    // console.log('updated ref array:', updatedArrayRef.current)
    const globalHasMoreComps = globalCompRef.current?.properties[0]?.length > getCompSheet.length || false;
    let regularCompsToUpdate = [...getCompSheet];
    let optimizedCompsToUpdate = [...getOptimizedComps];

    const updatedArrayNewRef = [...updatedArray];

    if(globalHasMoreComps){
      regularCompsToUpdate = [...globalCompRef.current.properties[0]];
      optimizedCompsToUpdate = [...globalCompRef.current.properties[1]];
    }
    
    // removed the stateful update arr check from here.
    const anyIsUpdatedFalse = 
    // Need to test out the length being greater than 0 here for update arr.
    // if ALL are true, don't let this be true.
    (compStreamObject.current.updated && compStreamObject.current.updated.length > 0 && compStreamObject.current.updated.some(isUpdated => isUpdated) && updatedArrayRef.current.length > 0 && updatedArray.some(isUpdated => !isUpdated)
    // && (updatedArray.some(isUpdated => !isUpdated) )
  ) || false;
    // console.log('the updated array ref being used for isfalse flag',updatedArrayRef)

  
    // console.log('the ORIGINAL update flags:', updatedFlags)
    if (anyIsUpdatedFalse || globalHasMoreComps) {
      // console.log('Updating comps as one or more conditions were met.',anyIsUpdatedFalse,globalHasMoreComps);
  
      regularCompsToUpdate.forEach((regularComp, index) => {
        if(anyIsUpdatedFalse){
          // console.log('running updated false')
          // this updatedflag should show you if its been updated and set in state or not.
          if(compStreamObject.current.properties.length>0 && updatedArray[index] === false && compStreamObject.current.updated[index] === true){
            // console.log('This index isn\'t updated:', index);
            const newRegularComp = compStreamObject.current.properties[0][index];
            const newOptimizedComp = compStreamObject.current.properties[1][index];
            // Log old and new values
            // console.log(`Comp ${index}: Old regularComp =`, regularComp, ", New regularComp =", newRegularComp);
    
            // Update comps if new values are available
              regularCompsToUpdate[index] = newRegularComp;
              // console.log(`Comp at index ${index} regularComp updated.`);
    
              optimizedCompsToUpdate[index] = newOptimizedComp;
              // console.log(`Comp at index ${index} optimizedComp updated.`);
    
            // Mark this index as updated
            updatedArrayNewRef[index] = true;
            // here, IF it is index #1 (if that is how it comes in for the first updated comp) - set state of update arr
          }
        }else if(globalHasMoreComps){
          console.log('runnin gglobal has more comps')

          const newRegularComp = globalCompRef.current.properties[0][index];
          const newOptimizedComp = globalCompRef.current.properties[1][index];

          regularCompsToUpdate[index] = newRegularComp;
          optimizedCompsToUpdate[index] = newOptimizedComp;
        }
      });
  
      // Set the state outside the loop
      if(anyIsUpdatedFalse){
        // console.log('the updated flags we are updating with',updatedArrayNewRef)
      setUpdatedArray(updatedArrayNewRef);
      }
      setCompSheet(regularCompsToUpdate);
      setOptimizedComps(optimizedCompsToUpdate);
      // Also fire filter 
      if(getReviewPage){
        // This is filtering EVERY TIME, which we don't want.
        if(getFilterIndexes){
        console.log('filtering from state update')
        filterIndexes(filterType);
        }
      }
    } else {
      // console.log('No updates were needed. -- HIT THIS "true" ELSE');
      
      if(getReviewPage){
        // Your running this every time even when state updates aren't necessary
        // you could use a ref or smth to track the most recently applied filter?
        if(getFilterIndexes){
          console.log('filtering')
          // but re-filtering was required
          filterIndexes(filterType);
        }
      }
      // Return true to indicate this is done updating.
        return true;
    }
  }, [getCompSheet, getOptimizedComps, updatedArray, filterType, filterIndexes, getFilterIndexes, getReviewPage]);
  

  // this has become redundant with your new function above.
  const resetUpdateArrayCallback = useCallback((length) => {
    console.log('Resetting updated array.');
    // setUpdatedArray([]);
    setUpdatedArray(new Array(length).fill(false));
    updatedArrayRef.current = new Array(length).fill(false);
    // return new Array(length).fill(false);
  }, [setUpdatedArray]);
  // console.log(getCompSheet)

  useEffect(() => {
    fetchCourtDates();
  }, [fetchCourtDates]);

  // This is creating updated array before any inventory updates happen idk if necessary anymore?
  // useEffect(() => {
  //   console.log('instantiating update array.')
  //   if (getCompSheet.length > 0) {
  //     // console.log('it has compsheet to set it too.')
  //     setUpdatedArray(new Array(getCompSheet.length).fill(false)); // Initialize state to track updated status
  //   }
  // }, []);

  return (
    <div className="bg-gray-200 flex flex-col h-full">
      {/* Weight widget ternary -- move this down into case review.*/}
      {weightWidget ?
        <SettingsWidget nyDataObj={nyDataObj} initializationFetch={initializationFetch} setWeightWidget={setWeightWidget}
          setLoading={setLoading}
        ></SettingsWidget>
        : null}

      {/* The below top nav section */}
      <div className="h-full overflow-y-hidden">
        <section className=" overflow-y-hidden max-w-screen-22xl px-6 h-full" id='home'>
          {/* Top navigration above map. */}
          {/* UPDATE DISPLAY FROM GETNEGOTIATION OBJ FOR MUNICODE AND TAXYEAR IN HEADER. */}
          <CompDashboard 
          ></CompDashboard>
          <>
          {getReviewPage ?
          <>
          <ReviewSettlements
          handleUpdateStateCallback={handleUpdateStateCallback}
          negotiationCases={memoizedFullNegotiationCases}
          // pass in the negotiation object here:
          filteredNegotiationCases={memoizedNegotiationFiltered}
          setFilterType={handleFilterChange}
          filterType={filterType}
          ></ReviewSettlements>
          </>
          :
          memoizedComps === undefined ? // update this to 
          <div className="mt-4">
            <MuniSelection nyDataObj={nyDataObj} globalCompRef={globalCompRef} yearDropdownOptions={yearDropdownOptions} upcomingCourtDates={upcomingCourtDates}
            />
          </div>
            :
            // {/* New container component, this will cannibalize the map, grid, and the misc */}
            memoizedComps === undefined ? (null) : (
              <CaseReview 
              // key={resetKey}
              // Pass in the resetKey (of comp) to force this to rerender everty time comp changes
              compObject={memoizedComps}
                handleUpdateStateCallback={handleUpdateStateCallback}              
                globalCompRef={globalCompRef} // idt you need this here
                caseObject={memoizedNegotiationObj} // if you pass in the negotiation object
                handleSetStreaming={handleSetStreaming}
                streaming={streaming}
                isUpdated={memoizedIsCompUpdated}
                updatedArray={updatedArray}
                // savedComps={Object.values(memoizedNegoiationObj?.comps || {})}
                compStreamObject={compStreamObject}
                savedComps={memoizedSavedComps}
                resetUpdateArrayCallback={resetUpdateArrayCallback}
                optimizedObject={memoizedOptimizedComps}>
              </CaseReview>
            )}
          </>
        </section>
      </div>
    </div>
  );
}
// memoize home
const HomeMemo = Home;
export default HomeMemo
// export default Home;
