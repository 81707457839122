import React from 'react';
import { DataGridPro, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExportContainer, GridCsvExportMenuItem, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';

const VisualizationTable = ({ headers, rows, MuniCode }) => {
    return (
        <div className="flex-1 overflow-hidden">
            <div className="h-full max-h-[77vh] min-h overflow-y-auto">
                <DataGridPro
                    columns={headers}
                    rows={rows}
                    pagination // Enables pagination
                    pageSize={100}
                    // Set minHeight directly in the style or via CSS
                    style={{ minHeight: '70vh' }}
                    // loading={rows.length === 0} // Show loading indicator if no rows
                    noRowsOverlay={
                        <div style={{ padding: '20px', textAlign: 'center' }}>
                            No data available
                        </div>
                    }
                    slots={{
                        toolbar: () => (
                            <div className='toolbar-buttons'>
                                <GridToolbarColumnsButton />
                                <GridToolbarFilterButton />
                                <GridToolbarExportContainer>
                                    <GridCsvExportMenuItem options={{fileName: `${MuniCode}_sales_time_trend`, separator: ','}} />
                                </GridToolbarExportContainer>
                                <GridToolbarQuickFilter />
                            </div>
                        )
                    }}
                />
            </div>
        </div>
    );
};

export default React.memo(VisualizationTable);
