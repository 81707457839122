import React, { } from 'react';
import { isEqual } from 'lodash'; // Assuming you are using lodash for deep comparison
import GraphOverlay from './GraphOverlay.js';

const GraphContainer = ({
    nyDataObj,
    yearDropdownOptions,
}) => {
    // updated this entire component. use this only to render multiple plots now.
    return (
        <div className='content relative flex flex-col h-full bg-gray-200'>
            
            {/* This acts as the headers, and currently graph overlay. */}
            <div className='content relative h-full'>
                <GraphOverlay nyDataObj={nyDataObj} yearDropdownOptions={yearDropdownOptions} >
                    {/* here we need to pass in default params IF they've been set from loadgraph. */}
                </GraphOverlay>
            </div>

        </div>
    );
}

const deepCompare = (prevProps, nextProps) => isEqual(prevProps, nextProps);

export default React.memo(GraphContainer, deepCompare);
