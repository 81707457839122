import React, { useState, useCallback, useEffect, useRef } from 'react';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import throttle from 'lodash.throttle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import useResizeHandler from '../../common/ResizeHandler';
import { calculateHeaders } from '../../common/CalculateHeaderFlex';
import {debounce} from 'lodash';



// we passs in the already filteredheaders into this component
  const SubjectDataGridRow = ({ headers, subject, columnVisibilityModel, updateRefWithSubj, unsavedChangesRef, handleSetPropertyInfo }) => {
    const containerRef = useRef(null);
    const [reviewed, setReviewed] = useState(false);
    const [editingCell, setEditingCell] = useState(null);
    const [editableValue, setEditableValue] = useState('');
    // console.log(headers)
    const [calculatedHeaders, setCalculatedHeaders] = useState(headers); // State for calculated headers
    // const headers = headers.filter((header) => {
    //     return columnVisibilityModel[header.field] !== false;
    // });

    // Resize handler
    useEffect(() => {
      const handleResize = debounce(() => {
        console.log('resized')
        const containerWidth = document.documentElement.clientWidth - 50;
          const updatedHeaders = calculateHeaders(headers, columnVisibilityModel, containerWidth);
          setCalculatedHeaders(updatedHeaders); // Update headers dynamically
      }, 300); // Debounce duration
  
      window.addEventListener('resize', handleResize); // Attach listener
      return () => {
        window.removeEventListener('resize', handleResize); // Cleanup on unmount
        handleResize.cancel(); // Cancel any pending debounced calls
      };
    }, [headers, columnVisibilityModel]);

    // console.log('calculatedheaders',c)


    const imputedValueCheck = useCallback((header, stringValue) => {
        // console.log('running imputed value check for')
        // console.log('header', header);
        // console.log('stringValue', stringValue);
    
        if (header.field === 'SaleDate') {
            if (stringValue) {
                // Convert stringValue to a Date object if it isn't already
                const dateValue = typeof stringValue === 'string' ? new Date(stringValue) : stringValue;
    
                // Check if the resulting date is valid
                if (!isNaN(dateValue.getTime())) {
                    const month = dateValue.getMonth() + 1;
                    const day = dateValue.getDate();
                    const year = dateValue.getFullYear();
                    const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
                    return <span>{formattedDate}</span>;
                }
                return; // Return nothing if the date is invalid
            }
        }
    
        // Handle other fields
        let value = stringValue; // removed the default handler to set to be blank here.
        if (stringValue === 0) {
            value = 0;
        }
        if (typeof value === 'string' && value.includes('*')) {
            return (
                <div style={{ color: '#d32f2f' }} className="h-full w-full flex items-center justify-center">
                    <span className="text-red-500 h-full w-full hover:text-blue-500">{value}</span>
                </div>
            );
        } else if (typeof value === 'number') {
            return <span style={{ textAlign: header.align }}>{value.toLocaleString()}</span>;
        }
        return <span style={{ textAlign: header.align }}>{value}</span>;
    }, []);
    

    const handleUpdateRefWithSubj = useCallback((subject, originalSubj) => {
        if (typeof updateRefWithSubj === 'function') {
            updateRefWithSubj(subject, originalSubj);
        }
    }, [updateRefWithSubj]);

    const handleCellDoubleClick = (header) => {
        const rawValue = subject[header.field];
    
        if (header.editable) {
            // if the value is imputed (decimal > 2 digits) then clear it out.
            const shouldClearValue = typeof rawValue === 'number' && rawValue % 1 !== 0 && rawValue.toString().split('.')[1].length > 2;
            setEditingCell(header.field);
            setEditableValue(shouldClearValue ? '' : rawValue); // Clear if decimal has more than 2 digits, otherwise keep value
        }
    };

    const handleSave = (value, force = null) => {
        // If the value is an empty string, revert to the original value
        if (value === '') {
            value = subject[editingCell]; // Revert to original value if blank
        }
    
        // Only update if the new value is different from the current value
        if (value !== subject[editingCell] || force === 'force') {
            // Get the existing unsaved changes
            const currentUnsaved = unsavedChangesRef.current.unsavedSubject || {};
    
            // Create a new object to hold the updated data
            const updatedSubject = { ...currentUnsaved, [editingCell]: value };
    
            // Ensure 'pid' is not duplicated, add it if it does not exist
            if (!updatedSubject.pid) {
                updatedSubject.pid = subject.PropertyInfo.parcel_id;
            }
    
            // Update the ref with the new merged data
            handleUpdateRefWithSubj(updatedSubject, subject);
        }
    
        // Reset the editing state
        setEditingCell(null);
    };

    const handleCancel = () => {
        setEditingCell(null);
    };

    const handlePropertyInfoClick = () => {
        // callback to pass up property info for subj record card
        handleSetPropertyInfo(subject,true);
    };

    return (
        <div
            className={`items-center flex flex-grow ${subject.RepId === '' ? '' : 'bg-yellow-200'}`}
            ref={containerRef}
            style={{ borderTop: "1px solid #ddd" }}
        >
    {calculatedHeaders.map((header, index) => {
        const rawValue = subject[header.field];
        const valueType = header.type;
        let value = rawValue;
        if (rawValue !== '' && rawValue !== undefined && rawValue !== null) {
            value = header.valueGetter
                ? header.valueGetter(subject[header.field])
                : rawValue;
        }

    const isUnsaved = unsavedChangesRef.current?.unsavedSubject?.[header.field] !== undefined;

    // Determine if the header should use PropertyInfoTooltip
    // const shouldRenderPropertyInfoTooltip = header.field === "PropertyInfo";

    if(header.field==='actions' || header.field==='id'){ // custom rendering for action.
        return(
            <div style={{ minWidth: "65px", maxWidth: "65px", outlineOffset: "-1px" }} key={header.field}>
            <div className="flex items-center justify-center w-full h-full">
                <div
                    className={`flex items-center justify-center w-5 h-5 rounded-full ${reviewed ? "bg-green-500" : ""}`}
                    onClick={() => setReviewed(!reviewed)}
                >
                    <CheckCircleOutlinedIcon className="hover:cursor-pointer" />
                </div>
            </div>
        </div>
        )
    }
    if (header.field === 'PropertyInfo') {
        return (
            <div
                key={header.field}
                className="MuiDataGrid-cell hover:text-blue-500"
                role="gridcell"
                style={{
                    width: header.calcWidth,
                    minWidth: header.minWidth || '50px',
                    cursor: 'pointer',
                    color: isUnsaved ? '#facc15' : 'inherit',
                    textDecoration: 'underline',
                }}
                onClick={handlePropertyInfoClick}
            >
                <span>{subject.PropertyInfo?.Address || 'No Address'}</span>
            </div>
        );
    }

    return (
        <div
            key={header.field}
            className={`MuiDataGrid-cell hover:text-blue-500 ${header.align === "left" ? "MuiDataGrid-cell--textLeft" : ""} ${header.editable ? "MuiDataGrid-cell--editable" : ""}`}
            role="gridcell"
            tabIndex={header.editable ? "0" : "-1"}
            style={{
                width: header?.calcWidth,
                minWidth: header.minWidth || "50px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                // borderLeft: "2px solid #ddd",
                outlineOffset: "-1px",
                textOverflow: "ellipsis",
                textAlign: header.type === "number" ? "right" : header.align,
                color: isUnsaved ? '#facc15' : 'inherit', // Apply color if unsaved
            }}
            onDoubleClick={() => handleCellDoubleClick(header)}
            >
                {/* There is an issue w the imputed value check here */}
            {editingCell === header.field ? (
                <EditingCell
                    value={editableValue}
                    type={header.type}
                    width={header.calcWidth}
                    dropdownOptions={header.valueOptions}
                    onSave={handleSave}
                    onCancel={handleCancel}
                />
            ) : isUnsaved ? (
                unsavedChangesRef.current.unsavedSubject[header.field]
            ) : (
                header.valueGetter
                    ? imputedValueCheck(header, value)
                    : header.renderCell
                    ? header.renderCell({ value: value })
                    : value || 'None'
            )}
        </div>
    );
    })}
    <div style={{ minWidth: "15px", maxWidth: "15px", outlineOffset: "-1px" }}></div>
</div>
);};
SubjectDataGridRow.whyDidYouRender = true;
export default SubjectDataGridRow;

const EditingCell = React.memo(({ value, onSave, onCancel, type, width, dropdownOptions }) => {
    const [inputValue, setInputValue] = useState(value);
    const inputRef = useRef(null);
    
        useEffect(() => {
            const handleClickOutside = (event) => {
                 // special handling for onclick function was required here (the input change wasn't working b/c click out upate fires first)
                if (event.target.tagName === 'LI') {
                    onSave(event.target.innerText,'force'); // add force param to force the update to go through / delineate between a regular save event.
                }
                if (inputRef.current && !inputRef.current.contains(event.target)) {
                    onSave(inputValue);
                }
            };
            document.addEventListener('mousedown', handleClickOutside);
            return () => document.removeEventListener('mousedown', handleClickOutside);
        }, [inputValue, onSave]);
    
        const handleChange = (event) => {
            console.log('changing')
            console.log(event.target.value)
            setInputValue(event.target.value);
        };

        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                onSave(inputValue);
            }
            if (event.key === 'Escape') {
                onCancel();
            }
        };
    
        return (
            <div
                ref={inputRef}
                style={{ 
                    width: width || '100%', // Ensure full width if not specified
                    height: '100%', // Full height of parent
                    display: 'flex', // Enable flex for children
                    alignItems: 'center', // Center items vertically
                    padding: 0, // Remove default padding
                    boxSizing: 'border-box', // Include border in element's total width and height
                    position: 'relative', // Ensure the container is positioned for children to expand
                }}
            >
                {type === 'singleSelect' && (
                    <Select
                        value={inputValue}
                        autoFocus
                        fullWidth
                        defaultOpen={true}
                        MenuProps={{ // this needs to be updated to open in the same manner as the grid one. but works fo rnow.
                            anchorOrigin: {
                              vertical: 'top',
                              horizontal: 'center'
                            },
                            transformOrigin: {
                              vertical: 'bottom',
                              horizontal: 'center'
                            }
                          }}
                        sx={{
                            flex: 1, // Ensure TextField expands to full width
                            height: '100%', // Ensure full height of parent container
                            padding: 0, // Remove internal padding
                            margin: 0, // Remove margins
                            '& .MuiOutlinedInput-root': {
                                border: '2px solid #ddd',
                                borderRadius: 0,
                                height: '100%', // Ensure full height of the input
                            },
                            '& .MuiInputBase-input': {
                                paddingTop: 0,
                                paddingBottom: 0,
                                height: '100%', // Ensure full height of the input
                                boxSizing: 'border-box', // Include padding and border in height calculation
                            },
                            // Optional: Override specific styles if needed
                            '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                                paddingTop: 0,
                                paddingBottom: 0,
                            },
                        }}
                    >
                        {dropdownOptions.map((option) => (
                            <MenuItem key={option} value={option}
                            >
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                )}
                {type === 'number' && (
                    <TextField
                        type="number"
                        value={inputValue}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        autoFocus
                        fullWidth
                        sx={{
                            flex: 1, // Ensure TextField expands to full width
                            height: '100%', // Ensure full height of parent container
                            padding: 0, // Remove internal padding
                            margin: 0, // Remove margins
                            '& .MuiOutlinedInput-root': {
                                border: '2px solid #ddd',
                                borderRadius: 0,
                                height: '100%', // Ensure full height of the input
                            },
                            '& .MuiInputBase-input': {
                                paddingTop: 0,
                                paddingBottom: 0,
                                height: '100%', // Ensure full height of the input
                                boxSizing: 'border-box', // Include padding and border in height calculation
                            },
                            // Optional: Override specific styles if needed
                            '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                                paddingTop: 0,
                                paddingBottom: 0,
                            },
                        }}
                    />
                )}
                {type === 'text' && (
                    <TextField
                        type="text"
                        value={inputValue}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        autoFocus
                        fullWidth
                        InputProps={{
                            sx: { 
                                padding: 0, // Remove padding
                                height: '100%', // Ensure full height
                                boxSizing: 'border-box', // Include padding and border in height calculation
                            }
                        }}
                    />
                )}
            </div>
        );
    }
    );
    