import React, { useMemo, useEffect, useRef, useState } from 'react';
import { DataGridPro, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import { useDemoData } from '@mui/x-data-grid-generator';
import { usePersistedDataStore, useLocalDataStore } from '../store';
import { useNavigate } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material'; // Import MUI components
import CircularProgress from '@mui/material/CircularProgress';

const CaseNavigation = ({ address, compNumber }) => {
    const setReviewPage = useLocalDataStore((state) => state.setReviewPage);
    const navigate = useNavigate();
    const handleClick = () => {
        const params = new URLSearchParams(window.location.search);
        params.set('comp', compNumber);
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        setReviewPage(false);
        navigate(newUrl);
    };

    return (
        <div>
            <span onClick={handleClick} style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>
                {address}
            </span>
        </div>
    );
};

function SummaryCaseReviewTable({ handleCellClick, selectionModel, doneUpdating, fetchSalesData, filteredNegotiationCases, handleUpdateStateCallback, updateNegotiation, negotiationCases }) {
    // If you make this component always live poll the DB for updates, you can have it be standalone on website (for assessors, reps, etc.)
    const intervalRef = useRef(null);
    useEffect(() => {
      const startInterval = () => {
        if (doneUpdating || intervalRef.current) return; // Prevent starting if already done or interval exists
    
        intervalRef.current = setInterval(() => {
          updateNegotiation();
    
          if (handleUpdateStateCallback()) {
            console.log('its done updating');
            clearInterval(intervalRef.current);
            intervalRef.current = null; // Reset interval reference
          }
        }, 1000);
      };
    
      startInterval();
    
      return () => {
        clearInterval(intervalRef.current);
        intervalRef.current = null; // Ensure cleanup
      };
    }, [handleUpdateStateCallback, updateNegotiation, doneUpdating]);
    

    const handleSelectionChange = (newSelection) => {
        handleCellClick(newSelection);
    };

    const checkRepID = (cases) => {
      return cases.every(c => !c.RepID || (typeof c.RepID === 'string' && c.RepID.trim() === ''));
    };
    

    const headers = useMemo(() => {
      const defaultHeaders = [
        { field: "id", headerName: "#", type: 'number', align: "center", headerAlign: 'center', maxWidth: 50 },
        // Address
        { 
          field: "Address", 
          headerName: "Address", 
          align: "left", 
          editable: false, 
          minWidth: 120, 
          flex: 1, 
          renderCell: (params) => {
            return params.row.Address ? (
              <CaseNavigation
                address={params.row.Address}
                compNumber={params.row.Comp}
              />
            ) : (
              <div style={{ color: '#888', fontStyle: 'italic' }}>Loading...</div>
            );
          }
        },
        { field: "PID", headerName: "PID", type: 'string', align: "left", flex: 0.6 },
        // scar id
        { field: 'Name', headerName: 'Client', type: 'string', align: 'left', flex: 0.6 },
        { 
          field: "SCARID", 
          headerName: "SCAR Id", 
          type: 'string', 
          align: "left", 
          flex: 0.6,
          renderCell: (params) => {
            return params.row.SCARID ? (
              params.row.SCARID
            ) : (
              <div style={{ color: '#888', fontStyle: 'italic' }}>None</div>
            );
          }
        },
        { field: "AssessmentTown", headerName: "Assessment", type: 'string', align: "left", valueGetter: (value) => value != null && !isNaN(value) ? Number(value.toFixed(0)).toLocaleString() : '', flex: 0.6 },
        { field: "IFMVTown", headerName: "Eq Mkt Val", type: 'number', align: "left", valueGetter: (value) => { return !isNaN(value) ? Number(value) : null; }, valueFormatter: (value) => { return !isNaN(value) ? `$${value.toLocaleString()}` : ''; }, flex: 0.6 },
        // market value
        {
          field: "MarketValue",
          headerName: "Workup Value",
          type: 'number',
          align: "left",
          headerAlign: "left",
          valueFormatter: (value) => {
            return !isNaN(value) ? `$${Math.round(value).toLocaleString()}` : 'Loading...';
          },
          renderCell: (params) => {
            if (params.row.Address === undefined || params.row.Address === '' || params.row.Address === null) {
              return <div style={{ color: '#888', fontStyle: 'italic' }}>Loading...</div>;
            }
            if (params.row.MarketValue === null) {
              return <div style={{ color: '#888', fontStyle: 'italic' }}>None</div>;
            }
            return !isNaN(params.row.MarketValue)
              ? `$${Math.round(params.row.MarketValue).toLocaleString()}`
              : 'Loading...';
          },
          flex: 0.6
        },
        // Scar det value
        {
          field: "SCARDeterminationValue",
          headerName: "Settlement Value",
          type: 'number',
          align: "left",
          renderCell: (params) => {
            if (params.row.Address === undefined || params.row.Address === '' || params.row.Address === null) {
              return <div style={{ color: '#888', fontStyle: 'italic' }}>Loading...</div>;
            }
            if (!params.row.SCARDeterminationValue || !params.row.RAR) {
              return <div style={{ color: '#888', fontStyle: 'italic' }}>None</div>;
            }
            const settlementValue = params.row.Settled
              ? params.row.SCARDeterminationValue / params.row.RAR
              : null;
            return !isNaN(settlementValue)
              ? `$${Math.round(settlementValue).toLocaleString()}`
              : 'Loading...';
          },
          flex: 0.6
        },
        // pct reduction
        {
          field: "pctReduction",
          headerName: "Pct Reduction",
          type: 'number',
          align: "left",
          headerAlign: "left",
          renderCell: (params) => {
            // Leaving option open to swap this, so 'good' reductions are negative percents, and higher values are positive %.
            if (
              params.row.Address === undefined ||
              params.row.Address === '' ||
              params.row.Address === null
            ) {
              return <div style={{ color: '#888', fontStyle: 'italic' }}>Loading...</div>;
            }

            if (!params.row.IFMVTown || !params.row.RAR) {
              return <div style={{ color: '#888', fontStyle: 'italic' }}>None</div>;
            }
            
            let pctReduction = 0;
            
            if (params.row.SCARDeterminationValue) {
              // Calculate based on SCARDeterminationValue
              pctReduction = params.row.Settled
                ? (((params.row.SCARDeterminationValue / params.row.RAR) - params.row.IFMVTown ) / params.row.IFMVTown) * 100
                : 0;
                // if there's no determination, show projected reduction based on workup value
            } 
            // Prioritize offer value to calculate percentage next
            else if (params.row.OfferValue && params.row.IFMVTown) {
              pctReduction = ((params.row.OfferValue - params.row.IFMVTown) / params.row.IFMVTown) * 100;
              // Determine the color
              const color = pctReduction > 0 ? 'red' : '#888'; // Red for negative values, gray otherwise

              return (
                <span style={{ color }}>
                  {pctReduction.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%
                </span>
              );
            } else if (params.row.MarketValue && params.row.IFMVTown) {
              pctReduction = ((params.row.MarketValue - params.row.IFMVTown) / params.row.IFMVTown) * 100;
              // Determine the color
              const color = pctReduction > 0 ? 'red' : '#888'; // Red for negative values, gray otherwise

              return (
                <span style={{ color }}>
                  {pctReduction.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%
                </span>
              );
            } 
            else {
              return <div style={{ color: '#888', fontStyle: 'italic' }}>None</div>;
            }
          
            if (pctReduction < 0 && pctReduction > -0.24) {
              pctReduction = 0;
            }
            
            // lets flipt his so that reductions are actually negative, not positive numbers
            const color = pctReduction < -0.24 ? 'green' : pctReduction > -0.24 ? 'red' : 'black';
            
            return (
              <span style={{ color }}>
                {pctReduction.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%
              </span>
            );
          },
          flex: 0.6
        },          
        // offer value - need this to recalc based on neg obj.
        { 
          field: "OfferValue", 
          headerName: "Offer", 
          type: 'number', 
          align: "left", 
          headerAlign: "left", 
          flex: 0.6,
          renderCell: (params) => {
            return params.row.OfferValue ? (
              `$${params.row.OfferValue.toLocaleString()}`
            ) : (
              <div style={{ color: '#888', fontStyle: 'italic' }}>None</div>
            );
          }
        },

        // "Status" column here
        {
          field: "status",
          headerName: "Status",
          type: 'string',
          align: "left",
          renderCell: (params) => {
            const isSettled = params.row.Settled;
            let displayText = "Open"; // Default to "Open"
            
            let statusStyle = {
              fontWeight: 'bold', // Default to bold for "Open"
              color: 'black' // Default color
            };
          
            // If isSettled is true, display "Settled" and make it green
            if (isSettled === true) {
              displayText = "Settled";
              statusStyle.color = 'green';
              statusStyle.fontWeight = 'normal'; // Normal font weight for "Settled"
            }
            // If isSettled is a string that starts with "BAR", display "BAR Only" and make it black
            else if (typeof isSettled === 'string' && isSettled.startsWith("BAR")) {
              displayText = "BAR Only";
              statusStyle.color = 'black';
              statusStyle.fontWeight = 'normal'; // Normal font weight for "BAR Only"
            }
          
            return (
              <span style={statusStyle}>
                {displayText}
              </span>
            );
          },
          
          flex: 0.6
        },
      ];
    
      // Conditionally add the "Rep ID" column before the "Status" column
      if (!checkRepID(negotiationCases)) {
        defaultHeaders.splice(defaultHeaders.findIndex(col => col.field === "status"), 0, {
          field: "RepID",
          headerName: "Rep ID",
          type: 'string',
          align: "center",
          flex: 0.6,
        });
      }
    
      return defaultHeaders;
    }, [negotiationCases]);

    const createRows = (cases, fullNegotiationCases) => {
      const scarStageExists = cases.some(c => c.SCARFiled === 1);
    
      return cases.map((currentCase,index) => {
        // Check if SCARFiled is 1 for the current case, if not, use BAR values
        const fullIndex = fullNegotiationCases.findIndex(c => c.PID === currentCase.PID);
        // First, check if RepID exists (is not a blank string)
        
        const isSCARFiled = currentCase.RepID !== "" && currentCase.RepID != null || currentCase.SCARFiled === 1;;  // Treat as SCARFiled if RepID exists or SCARFiled is already 1
    
        return {
          id: index+1,
          Comp: fullIndex + 1, // The comp number is based on the full array index
          Address: currentCase.Address,
          PID: currentCase.PID,
          Name: currentCase.Name,
          Town: currentCase.Town,
          ZipCode: currentCase.ZipCode,
          AssessmentTown: currentCase.AssessmentTown,
          OfferValue: currentCase.OfferValue || null,
          IFMVTown: currentCase.IFMVTown,
          SCARID: currentCase.SCARIndexNumber,
          MarketValue: currentCase.SubjectMarketValue,
          pctReduction: currentCase.PctReduction * 100,
          SalePrice: currentCase.SalePrice || null,
          SaleDate: currentCase.SaleDate || null,
          RAR: currentCase.RAR,
          RepID: currentCase.RepID,
          SCARDeterminationAction: isSCARFiled ? currentCase.SCARDeterminationAction : currentCase.BARDeterminationAction,
          SCARDeterminationValue: isSCARFiled ? currentCase.SCARDeterminationValue : currentCase.BARDeterminationValue,
          Settled: isSCARFiled
            ? ['S', 'SD', 'ST', 'W', 'NM', 'AH'].includes(currentCase.SCARDeterminationAction)
            : `BAR ${currentCase.BARDeterminationAction}`,
          SCARFiled: isSCARFiled, // SCARFiled is true if RepID exists or SCARFiled is already 1
        };
      });
    };
    
    const rows = useMemo(() => createRows(filteredNegotiationCases, negotiationCases), [filteredNegotiationCases, negotiationCases]);

    const tableProps = useMemo(() => ({
        density: 'compact',
        disableRowSelectionOnClick: true,
    }), []);

    return (
      <div className="flex-grow h-full overflow-auto">
        <div className='bg-white w-full h-[600px]'>
            <DataGridPro
                {...tableProps}
                rows={rows}
                columns={headers}
                pageSize={10}
                rowsPerPageOptions={[10, 25, 50, 100]}
                onRowSelectionModelChange={handleSelectionChange}
                rowSelectionModel={selectionModel}
                localeText={{
                  noRowsLabel: 'No cases match criteria',
                }}
                slots={{
                  toolbar: CustomToolbar, // Add a custom toolbar
              }}
                // slotProps={{
                //     columnMenu: {
                //         disableColumnMenu: true,
                //     },
                // }}
                disableColumnMenu
            />
        </div>

        </div>
    );
}


function CustomToolbar() {
  return (
      <div style={{ padding: '8px' }}>
          <GridToolbarQuickFilter />
      </div>
  );
}

export default React.memo(SummaryCaseReviewTable);
