import React, { useState, useEffect, useMemo, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LanguageIcon from '@mui/icons-material/Language';
import { toast } from 'react-toastify';
import { handleCopy, GetCompPhoto } from '../../common/helper_functions';
import axiosInstance from '../../axiosConfig';
import { useLocation } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const PropertyInfoPage = ({ compObject, handleCompViewClose, handleProcessDBUpdate={}, subject=false }) => {

  // Have it display subject.RepId if it exists in
  
  // console.log(compObject)
    // Destructure common fields
    const {
      parcel_id,
      SDName,
      MLSNumber,
      SalePrice,
      SaleDate,
      SDCode,
      RepId,
      Remarks,
      Town,
      ZipCode,
  } = compObject;

  // Destructure Address, Town, Zip conditionally

      // Destructure from PropertyInfo if subject is false
      const { Address } = compObject.PropertyInfo;

  // set the image link to photo if it exists, and set the loading state for photo to a placeholder mui circular progress
  const [imgLink, setImgLink] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isGradientVisible, setIsGradientVisible] = useState(true);
  const remarksRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const comp = useMemo(() => {
    const compParam = queryParams.get('comp');
    return parseInt(compParam, 10) - 1 || 0;
  }, [queryParams.get('comp')]); // Focus only on the `comp` query param
  const isInitialMount = useRef(true); // Tracks if the component is mounting for the first time

  useEffect(() => {
    if (remarksRef.current) {
      const target = remarksRef.current;
      const scrollable = target.scrollHeight > target.clientHeight;
      setIsGradientVisible(scrollable);
    }
  }, [Remarks]);

  // Handle `comp` change
  useEffect(() => {
    if (isInitialMount.current) {
      // Skip Firing this on first render (to enable the popup to open)
      isInitialMount.current = false;
    } else {
      console.log("comp changed to:", comp);
      handleCompViewClose();
    }
  }, [comp, handleCompViewClose]);
  
  
  const addressCopy = `${Address}, ${Town}, ${ZipCode}`;
  // address copy of address, town, zip

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  console.log(compObject)

  console.log(compObject.RepId && compObject.RepId !== '')
  console.log(compObject.RepId)
  console.log(compObject.RepId !== '')

  const flagBadSale = async () => {
    try {
      const saleObj = { parcel_id, SalePrice, SaleDate };
      console.log(saleObj)

      const response = await axiosInstance.post('/flag-bad-sale', { saleObj });
      toast.success('Successfully flagged bad sale, re-running algorithm...', { position: 'top-right', autoClose: 2000 });
      console.log('Flagged bad sale:', response.data);
      // this kicks of the KNN update process, need to recreate the required object in this function
      handleProcessDBUpdate(parcel_id);
      // close the comp view
      handleCompViewClose();
    } catch (error) {
      console.error('Error flagging bad sale:', error);
      toast.error('Failed to flag bad sale!', { position: 'top-right', autoClose: 2000 });
    }
  };

  // See about a streetview toggle for the addres in this view (and default if no photo available)
  // get the photo for the comp
  useEffect(() => {
    setLoading(true);
    GetCompPhoto(parcel_id).then((res) => {
      setImgLink(res);
      setLoading(false);
    });
  }, [parcel_id]);

  // Update this, so that the sale information is displayed next to the address
  return (
    <div className="flex flex-col w-full h-[25vh] mb-[4px] bg-white shadow-md rounded-lg relative">
      {/* Top Bar with Action Buttons */}
      <div className="absolute top-0 right-0 flex space-x-2">
        {/* More Options */}
        {!subject && (
        <IconButton onClick={handleMenuOpen}>
          <MoreVertIcon />
        </IconButton>
        )}

        <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={flagBadSale}>Flag Bad Sale</MenuItem>
          <MenuItem disabled>Option 1</MenuItem>
          <MenuItem disabled>Option 2</MenuItem>
        </Menu>
        {/* Close Button */}
        <IconButton onClick={handleCompViewClose}>
          <CloseIcon style={{ color: 'red' }} />
        </IconButton>
      </div>

      {/* Main Content */}
      <div className="flex h-full w-full">
        {/* Image Section */}
        <div className="w-1/4 h-full bg-gray-200 flex items-center justify-center">
          {loading ? (
            <span>Loading...</span>
          ) : imgLink ? (
            <img src={imgLink} alt="Property" className="max-w-full max-h-full" />
          ) : (
            <span>No Image</span>
          )}
        </div>

        {/* property details container */}
        <div className="flex-1 flex flex-col ">
          {/* Details Section */}
          <div className="px-2 flex flex-col ">
            {/* Address with Copy */}
            <div className="flex items-center">
              <h2 className="text-lg font-bold underline hover:text-blue-500 cursor-pointer" onClick={() => window.open(`https://www.google.com/search?q=${encodeURIComponent(addressCopy)}`, '_blank')}>{Address}</h2>
              {/* <IconButton className="ml-1 p-1">
                <LanguageIcon sx={{fontSize: '22px'}}/>
              </IconButton> */}
              <IconButton onClick={() => handleCopy(Address, 'Address')} className="ml-1 p-1">
                <ContentCopyIcon sx={{fontSize: '18px'}}/>
              </IconButton>
            </div>

            {/* PARCEL ID */}
            <div className='flex items-center' 
                onClick={() => handleCopy(parcel_id, 'Parcel ID')}
            >
              <span className="text-sm font-bold">Parcel ID: </span>
              <span
                className="text-sm cursor-pointer"
              >
                {parcel_id}
              </span>
              <IconButton
                size="small"
                // onClick={() => handleCopy(parcel_id, 'Parcel ID')}
                className="ml-1"
                sx={{ padding: '1px' }} // Shrink the button padding  
              >
                <ContentCopyIcon sx={{ fontSize: 14 }} /> 
              </IconButton>
            </div>

            {compObject.RepId && compObject.RepId !== ''?
            <div className='w-fit bg-yellow-200'>
              <span className="text-sm font-bold">Rep ID: </span>
              <span
                className="text-sm cursor-pointer"
              >
              {compObject.RepId}
              </span>
              </div>
            :
            null}



            {/* Parcel Details */}
            <div className="flex items-center border-b">
            {/* MLS Details */}
            {MLSNumber !== 'None' && (
              <div>
                <span className="text-sm font-bold">MLS Number: </span>
                <span className="text-sm">{MLSNumber}</span>
                <IconButton
                  size="small"
                  onClick={() => handleCopy(MLSNumber, 'MLS Number')}
                  sx={{ padding: '1px' }} // Shrink the button padding  
                  >
                    <ContentCopyIcon sx={{ fontSize: 14 }} /> 
                </IconButton>
              </div>
            )}

            {/* School District */}
            <div className={`${MLSNumber !== 'None'?`ml-2`:''}`}>
              <span className="text-sm font-bold">School District: </span>
              <span className="text-sm">
                {SDName} ({SDCode})
              </span>
            </div>
            
            </div>
            </div>

            {/* Remarks */}
            {Remarks && (
              <div className="relative overflow-auto flex-1">
                {/* Scrollable Content */}
                <div
                  className="text-sm px-2 overflow-auto whitespace-normal h-full"
                  onScroll={(e) => {
                    const target = e.target;
                    const scrollable = target.scrollHeight - target.clientHeight > 0;
                    const scrolledToBottom =
                      Math.ceil(target.scrollTop + target.clientHeight) >= target.scrollHeight;
                    setIsGradientVisible(scrollable && !scrolledToBottom);
                  }}
                  ref={remarksRef}
                >
                  <span className="text-sm font-bold">Remarks: </span>
                  {Remarks}
                </div>

                {/* Gradient Overlay */}
                {isGradientVisible && (
                  <div className="absolute bottom-0 left-0 w-full h-12 bg-gradient-to-t from-gray-200 to-transparent pointer-events-none">
                    {/* Scroll Down Indicator */}
                    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 text-center text-[10px] font-medium text-gray-600">
                      <span>Scroll down</span>
                      <ArrowDropDownIcon sx={{ fontSize: 14, verticalAlign: 'middle' }} />
                    </div>

                  </div>
                )}
              </div>
            )}

          </div>
        </div>
    </div>
  );
};

export default PropertyInfoPage;