export const calculateHeaders = (headers, columnVisibilityModel, containerWidth) => {
  const actionsWidth = 65; // Fixed width for the 'actions' column
  const totalWidth = containerWidth; // Full container width
  let contentWidth = totalWidth - actionsWidth; // Initial content width without 'actions'

  // Step 1: Filter headers based on visibility and exclude 'actions'
  const visibleHeaders = headers.filter(
    (header) => header.field !== 'actions'
  );

  // Calculate total flex value of visible headers
  const totalFlex = visibleHeaders.reduce((sum, header) => sum + header.flex, 0);

  // Initialize headers with calculated widths based on flex
  let calculatedHeaders = visibleHeaders.map((header) => ({
    ...header,
    calcWidth: (header.flex / totalFlex) * contentWidth,
  }));

  // Adjust headers for constraints
  let usedWidth = 0;
  let isAdjusting = true;

  while (isAdjusting) {
    isAdjusting = false; // Reset adjustment flag
    const adjustedHeaders = [];
    let recalculatedFlexSum = 0;

    calculatedHeaders.forEach((header) => {
      if (header.minWidth && header.minWidth > header.calcWidth) {
        header.calcWidth = header.minWidth; // Set to minWidth
        usedWidth += header.calcWidth;
        isAdjusting = true;
      } else if (header.calcWidth < 50) {
        header.calcWidth = 50; // Set to minimum width of 50px
        usedWidth += header.calcWidth;
        isAdjusting = true;
      } else if (header.calcWidth !== 50 && header.calcWidth !== header.minWidth) {
        recalculatedFlexSum += header.flex; // Sum flex for headers not constrained
      }
      adjustedHeaders.push(header);
    });

    contentWidth = totalWidth - actionsWidth - usedWidth; // Recalculate content width

    // Recalculate widths for headers not constrained to 50px or minWidth
    if (isAdjusting && recalculatedFlexSum > 0) {
      calculatedHeaders = adjustedHeaders.map((header) => {
        if (header.calcWidth > 50 && (!header.minWidth || header.calcWidth !== header.minWidth)) {
          return {
            ...header,
            calcWidth: (header.flex / recalculatedFlexSum) * contentWidth,
          };
        }
        return header; // Keep adjusted headers as-is
      });
    } else {
      calculatedHeaders = adjustedHeaders; // No further adjustments needed
    }
  }

  // Add the 'actions' column back with fixed width as the FIRST column
  const actionsColumn = headers
    .filter((header) => header.field === 'actions')
    .map((header) => ({ ...header, calcWidth: actionsWidth }));

  const finalHeaders = [...actionsColumn, ...calculatedHeaders]; // Prepend the actions column

  // console.log('Final calculated headers:', finalHeaders);

  return finalHeaders;
};
