import React, { useRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MuiCard from "@mui/material/Card";
import { styled, ThemeProvider, createTheme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import { Magic } from "magic-sdk"; // Import Magic SDK
import { useNavigate, NavLink } from "react-router-dom";
import { useAuth } from "../AuthContextProvider";
import { GoogleIcon, FacebookIcon, SitemarkIcon } from './CustomIcons';

const theme = createTheme({
  palette: {
    mode: "light",
  },
});

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(1),
  margin: "auto",
  maxWidth: "450px",
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  padding: 20,
  marginTop: "10vh",
  position: "relative",
}));

const magic = new Magic(process.env.REACT_APP_MAGIC_PUBLISHABLE_KEY); // Initialize Magic SDK
const clientId = process.env.REACT_APP_GOOGLE_API_CLIENT_ID
const clientSecret = process.env.REACT_APP_GOOGLE_API_CLIENT_SECRET
const redirectUri = window.location.origin+process.env.REACT_APP_GOOGLE_AUTH_REDIRECT_URI

export default function SignIn() {
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [signingIn, setSigningIn] = useState(false);
  const emailRef = useRef();
  const [signinError, setSigninError] = useState(false);
  const navigate = useNavigate();
  const {user, login} = useAuth();

  const isEmailApproved = (email) => {
    const approvedEmails = ['james@aventine.ai', 'maegan@aventine.ai', 'jrburns@aventine.ai', 'brennan@aventine.ai'];
    return approvedEmails.includes(email);
  };
  // const approvedEmails = ['james@aventine.ai', 'maegan@aventine.ai', 'jrburns@aventine.ai']; // Add your approved emails

  // Abstract to DB
  const userObject = {
    'james@aventine.ai': 1,
    'maegan@aventine.ai': 3,
    'jrburns@aventine.ai': 16,
    'brennan@aventine.ai': 19,
  }

  // GOOGLE SIGNIN STUFF:
  useEffect(() => {
    if(signingIn) {
      setSigningIn(false);
    }
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
  
    if (code) {
      setSigningIn(true);
      // Exchange code for token
      exchangeCodeForToken(code)
        .then(data => {
          if (data.error) {
            throw new Error(data.error); // Handle token exchange error
          }
          const accessToken = data.access_token;
          // Fetch user info with access token
          return fetchUserInfo(accessToken);
        })
        .then(userInfo => {
          
          if (!isEmailApproved(userInfo.email)) {
            throw new Error('Unauthorized email domain');
          }

          // based on matching the userInfo.email to the key in the userObject, add in the user id to the userInfo object.
          userInfo.userId = userObject[userInfo.email] || null;
          login(userInfo);
          console.log('navigating')
          navigate('/table');
        })
        .catch(error => {
          console.error('Error:', error);
          if(error.message === 'Unauthorized email domain') {
            setEmailError(true);
            setEmailErrorMessage("This email is not authorized. Contact sales for access.");
            // setSigninError(true);
          }else{
          // setSignupError(true); // Set signup error
          // setSignupErrorText('Failed to sign in. Please try again.');
          }
          setSigningIn(false); // Stop the signing in spinner
        });
    }
  }, []);

  const fetchUserInfo = async (accessToken) => {
    const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    });
    const userInfo = await response.json();
    return userInfo;
  };

  const exchangeCodeForToken = async (authorizationCode) => {
    const response = await fetch('https://oauth2.googleapis.com/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({ // move these to env variable.
        code: authorizationCode,
        client_id: clientId,
        client_secret: clientSecret,
        redirect_uri: redirectUri, // Same as the redirect URI used in authorization request
        grant_type: 'authorization_code',
      }),
    });
    const data = await response.json();
    return data;
  };

  const handleGoogleRedirectSignIn = () => { 
    const scope = 'openid profile email';
    
    const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${encodeURIComponent(scope)}`;
    window.location.href = googleAuthUrl;
  //  setUser(true)
  };

  // Re-direct user off signin if they are already signed in
  useEffect(() => {
    // Call function to check if user is magic signed in already 
    checkUser()
    if(!user) return;
    // Navigate into app if user is already signed in
    console.log('nvagating')
    navigate('/table');
  },[user])

  // Check if user is already signed in with magic
  const checkUser = async() => {
    console.log('checking user')
    const isLoggedIn = await magic.user.isLoggedIn();
    console.log('is logged in:', isLoggedIn)
  if (isLoggedIn) {
    if(user) return;
    const userInfo = await magic.user.getInfo();
    console.log(userInfo.email)
    userInfo.userId = userObject[userInfo.email] || null;
    login(userInfo)
    navigate('/table');
    // Handle already logged-in state (e.g., navigate to dashboard)
  } else {
    console.log('User is not logged in, proceeding with authentication.');
    // Proceed with magic link login
  }
}

  // MAGIC LINK STUFF:
  const handleMagicLinkSubmit = async (event) => {
    event.preventDefault();
    const email = emailRef.current.value;

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid email address.");
      return;
    }

    if (!isEmailApproved(email)) {
      setEmailError(true);
      setEmailErrorMessage("This email is not authorized. Contact sales for access.");
      return;
    }

    setEmailError(false);
    setEmailErrorMessage("");
    
    // Idk if this signing in is correct, need an inbewteen state to show the magic link is being sent
    setSigningIn(true);

    try {

      // What is the timout period for these otp tokens?
      // Send magic link
      const did = await magic.auth.loginWithEmailOTP({ email});
      console.log(`DID Token: ${did}`);
      // await magic.auth.loginWithMagicLink({ email, redirectURI: window.location.origin+'/signin', showUI: true, });
      // console.log("Magic link sent to", email);
      // Handle successful login (e.g., navigate to dashboard)
      // here, update the userlocalhost storage with this emails user ID
      const userInfo = {
        email,
        userId: userObject[email] || null
      };
      login(userInfo)
      // setSigningIn(false);
    } catch (error) {
        console.error("Magic link error:", error);
        // Log the error for debugging but avoid user-facing disruptions
        if (error.message.includes("User denied account access")) {
          console.log("User denied account access. No action needed.");
        } else {
          console.log("An unexpected error occurred:", error.message);
        }
      console.error("Magic link error:", error);
      setSigningIn(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SignInContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
          {signingIn ? (
            <Typography variant="h4" className="text-center">
              Signing in...
              <CircularProgress />
            </Typography>
          ) : (
            <>
              <Typography variant="h4" className="text-center">
                Sign in
              </Typography>
              <Typography variant="body2" className="text-center">
                Welcome, please sign in to continue
              </Typography>
              <Box
                component="form"
                onSubmit={handleMagicLinkSubmit}
                noValidate
                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              >
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <TextField
                    error={emailError}
                    helperText={emailErrorMessage}
                    id="email"
                    type="email"
                    onChange={() => {
                      setEmailError(false);
                      setEmailErrorMessage("");
                    }}
                    placeholder="your@email.com"
                    required
                    fullWidth
                    inputRef={emailRef}
                  />
                </FormControl>
                <Button type="submit" variant="contained">
                  Sign in with Email
                </Button>
              </Box>
              <Divider sx={{ mt: 1 }}>OR</Divider>
              <Button
              type="submit"
              fullWidth
              variant="outlined"
              onClick={() => handleGoogleRedirectSignIn()}
              startIcon={<GoogleIcon />}
              // disabled={signingIn}
            >
              Sign in with Google
            </Button>

            <NavLink to="/" variant="body2" align="center" className={'text-blue-500 underline'} sx={{ mt: 1 }}>
              Contact sales for access
            </NavLink>
            </>
          )}
        </Card>
      </SignInContainer>
    </ThemeProvider>
  );
}
