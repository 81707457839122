import React, { useState, forwardRef } from 'react';
import TextField from '@mui/material/TextField';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import InputAdornment from '@mui/material/InputAdornment';

const ChatInput = forwardRef(({ onSend }, ref) => {
    const [input, setInput] = useState('');

    const handleSend = () => {
        if (input.trim()) {
            onSend(input);
            setInput('');
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSend();
        }
    };

    return (
        <div className="flex w-full max-w-[800px]"> {/* Set max-width and center the input */}
            <div className="flex-grow"> {/* Allow this div to grow */}
                <TextField
                    inputRef={ref}
                    variant="outlined"
                    fullWidth
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Type a message..."
                    className="flex-1"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start" onClick={handleSend} disabled={input === ''}>
                                <div
                                    className={`${
                                        input === '' 
                                            ? 'bg-gray-300 hover:cursor-default' // Disabled state with transparent background
                                            : ' hover:cursor-pointer' // Active state with background color
                                    } p-1 rounded-2xl`}
                                >
                                    <ArrowUpwardIcon style={{ color: input ? 'blue' : 'inherit' }} />
                                </div>
                            </InputAdornment>
                        ),
                        
                    }}
                />
            </div>
        </div>
    );
});

export default ChatInput;
