import axios from 'axios';

// Determine the environment
const environment = process.env.REACT_APP_ENV || 'production';
console.log('Environment:', environment);

// Create Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Your API base URL
  timeout: 900000000, // Adjust timeout as needed
  headers: {
    'Content-Type': 'application/json',
    // You may have other default headers here
  },
  referrerPolicy: {
    policy: 'strict-origin-when-cross-origin',
  },
});

// Add retry interceptor
// axiosInstance.interceptors.response.use(
//   (response) => response, // Return the response if no error
//   async (error) => {
//     const config = error.config;

//     // If `config` doesn't exist or has no retries, reject the error
//     if (!config || config.__retryCount >= 2) {
//       return Promise.reject(error);
//     }

//     // Initialize the retry count if it doesn't exist
//     config.__retryCount = config.__retryCount || 0;

//     // Increment the retry count
//     config.__retryCount += 1;

//     console.warn(`Retrying request (${config.__retryCount}/2)...`);

//     // Wait for a specified amount of time before retrying
//     await new Promise((resolve) => setTimeout(resolve, 1000)); // 1 second delay

//     // Retry the request
//     return axiosInstance(config);
//   }
// );

export default axiosInstance;
