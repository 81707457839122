import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate, useSearchParams, useLocation, NavLink } from 'react-router-dom';
import {useCompStore, useAdminSettingsStore, useLocalDataStore, usePersistedDataStore} from '../store.js';
import { toast } from 'react-toastify';
import axiosInstance from '../../axiosConfig.js'
import _, {isEqual} from 'lodash';
import Button from '@mui/material/Button';
import CompSearch from './CompSearch.js';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

function CompDashboard({}){
  // setloading can be deleted once your save comps is fixed.
    const setCompSheet = usePersistedDataStore((state) => state.setCompSheet) // set comps
    const setShowSettledCases = useAdminSettingsStore((state) => state.setShowSettledCases)
    const getCompSheet = usePersistedDataStore((state) => state.compSheet)
    const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj)
    const setNegotiationObj = usePersistedDataStore((state) => state.setNegotiationObj)
    const setCaseNumber = usePersistedDataStore((state) => state.setCaseNumber)
    const setFetchLoad = usePersistedDataStore((state)=> state.setFetchLoad)
    const setOptimizedComps = usePersistedDataStore((state)=> state.setOptimizedComps)
    const setCourtMunis = usePersistedDataStore((state)=> state.setCourtMunis)
    const getReviewPage = useLocalDataStore((state)=> state.reviewPage)
    const setReviewPage = useLocalDataStore((state)=>state.setReviewPage)
    const navigate = useNavigate();
    const setCourtComps = usePersistedDataStore((state) => state.setCourtComps);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const comp = parseInt(queryParams.get('comp')) - 1 || 0 ; // Subtract 1
    const MuniCode = queryParams.get('MuniCode') || 'All';
    const courtDate = queryParams.get('CourtDate') || '';
    const taxYear = queryParams.get('TaxYear') || getNegotiationObj.TaxYear; // updating taxyear updates whole obj. Analyze refactors to see if performance issue in future.

    // This means you don't update on the state update, only on the review page load.
    const memoizedNegotiationCases = useMemo(() => getNegotiationObj.cases, [getNegotiationObj.cases]);
    // console.log('reloading')

    const handleSelectSubject = useCallback(({suggestion, index}) => {
      const queryParams = new URLSearchParams(location.search);
      console.log('selected subject')
      console.log(suggestion) // full address string
      setReviewPage(false)
      queryParams.set('comp', index + 1);
      // navigate to the new url
      navigate(`${location.pathname}?${queryParams.toString()}`);
    }, [memoizedNegotiationCases, location.search]);

    // this component is probably inefficient because of the work it does on each render
  //   async function handleFlagNoSCAR(){
  //     console.log(getCompSheet[comp].parcel_id[0])
  //     try{
  //     const optimizeResponse = await axiosInstance.post(`/flag_case_scar_status`, { // passing in the indexes for pagination
  //                 Subject: getCompSheet[comp].parcel_id[0],
  //                 TaxYear: getNegotiationObj.TaxYear,
  //                 filingStatus: 0,
  //               });
  //       toast.success(`Flagged case to not file at Scar.`, {
  //         position: 'top-left',
  //         className: 'mt-10',
  //         autoClose: 3000,
  //     });
  //   }catch{
  //     toast.error('some Error occured', {
  //       position: 'top-left',
  //       className: 'mt-10',
  //       autoClose: 3000,
  //     })
  //   }
  // }   

    function handleClear(){ // removes your states for offers and comps.
      const updatedNegotiation = { // clone the outside obj, create the interior cases array.
        ...getNegotiationObj,
        cases: []
      }
      setCompSheet('') // remove the compsheet
      setFetchLoad(false)
      updatedNegotiation.municipality = 'All'
      updatedNegotiation.county = 'All'
      updatedNegotiation.village = 'All'
      updatedNegotiation.MuniCode = null
      updatedNegotiation.courtDate = 'All'
      updatedNegotiation.CourtRun = 0
      updatedNegotiation.courtDateSelectedMuni = null
      // udpatedNegotiation.
      setCaseNumber(0)
      setOptimizedComps([])
      setReviewPage(false)
      setCourtMunis([])
      setNegotiationObj(updatedNegotiation) // remove all offers
      setCourtComps([])
      setShowSettledCases(false)
      // Here, setshowsettled cases to false
      // setShow

      const url = new URL(location.pathname+location.search, window.location.origin);
      const searchParams = new URLSearchParams(url.search);
      // searchParams.set('view', 'regular');
      const newUrlString = `${url.pathname}?${searchParams.toString()}`;
      navigate(newUrlString);
      // SetCourt date
    }

    function setSettled(value){ // the .settlement is inherently set, becasuse it gets locked when this function runs.
      console.log(getNegotiationObj)
      const updatedSettlement = { // clone the outside obj, and the interior cases array.
        ...getNegotiationObj,
        cases: [...memoizedNegotiationCases]
        }

        if(updatedSettlement.cases[comp].settlement>getCompSheet[comp].IFMV[0]){
        toast.error('Settlement higher than current value!', { // display warning toast.
          position: toast.POSITION.TOP_RIGHT,
        });
        updatedSettlement.cases[comp].settlement = '' // and reset the settlement value to zero
      }else{
        updatedSettlement.cases[comp].settled=value
        // Should probably round the updated settlement vale to be
        console.log(getCompSheet[comp].RAR[0])
        console.log(updatedSettlement.cases[comp].settlement)
        // Calculate the Settled AV using RAR
        updatedSettlement.cases[comp].settledAV=updatedSettlement.cases[comp].settlement*getCompSheet[comp].RAR[0]
        
        // Set the type equal to 'S' -- may need to amend this to ST.
        updatedSettlement.cases[comp].type = 'ST'
      }
        setNegotiationObj(updatedSettlement)
    }

    function clearInputs(){ // eliminate settlement value
      const updatedNegotiation = { // clone the outside obj, and the interior cases array.
        ...getNegotiationObj,
        cases: [
          ...memoizedNegotiationCases
        ]
      }
      // Ensure that the element at comp exists or create it
      if (!updatedNegotiation.cases[comp]) { // create the object within the array.
        console.log('Creatng new Obj - this case has no prior val')
        updatedNegotiation.cases[comp] = {minOffer:'', maxOffer:'', settlement:'', settledAV:'', settled:false, type: ''};
      }
      updatedNegotiation.cases[comp].settlement = ''
      updatedNegotiation.cases[comp].settledAV = null
      updatedNegotiation.cases[comp].type = ''
      setNegotiationObj(updatedNegotiation)
    }

    return(
      <div className={`relative testing inline overflow-clip min-h-[400px]`}>
          {getCompSheet.length > 0 &&
          // THis is REALLY dumbly constructed between this and the review page. but works fo rnow.
          <div id='sticky' className={`my-0.5 flex justify-between items-center ${getReviewPage?'mb-[-35px]':''} border-b-1 border-gray-500`}>
              <div className="flex flex-col gap-3 sm:flex-row sm:items-center ">
                {/* Breadcrumbs below */}
                <nav>
                  <ol className="flex items-center gap-1">
                    <li className='flex'>

                      {/* display house if on review. */}
                      {getReviewPage?
                      <NavLink to="/table" className='flex items-center justify-center my-1' onClick={()=>handleClear()}>
                      <Button
                        // variant='contained'
                        variant='outlined'
                        sx={{ padding: '2px 8px' }}
                        startIcon={<KeyboardBackspaceIcon />}
                      >
                        Run New Area
                      </Button>
                      </NavLink>
                        :
                        // Back arrow here.
                        <Button onClick={()=>setReviewPage(true)}
                          startIcon={<KeyboardBackspaceIcon/>}
                          className='text-[#1976d2] font-medium'
                        >
                          {/* Here, put your specific breadcrumbs */}
                          {
                            (courtDate !== '' || MuniCode !== 'All') && (
                              <li className={`flex ${courtDate !== '' ? '' : 'text-center items-center mr-0.5'}`}>
                                {courtDate !== '' ? (
                                  <>
                                    {courtDate} - <span className="text-primary ml-1">{MuniCode + ' (' + taxYear + ')'}</span>
                                  </>
                                ) : (
                                  <div id="text" className="text-[#1976d2] font-medium" style={{ lineHeight: '1' }}>
                                    {MuniCode + ' (' + taxYear + ') '}
                                  </div>
                                )}
                              </li>
                            )
                          }
                          {' '}Summary
                        </Button>
                      }
                    </li>
                    
                    {/* Breadcrumb display - County / Muni / SCAR ER # */}
                    

                  </ol>
                </nav>

                {/* Can also place search here if want on the left side?? */}
              </div>

              {/* PASS IN REQUIRED PROPS TO SEARCH HERE: */}
              <div className="flex items-center">
                <CompSearch
                placeholderText='Subject Search'
                compSelectCallback={handleSelectSubject}
                searchData={memoizedNegotiationCases}
                ></CompSearch>
              </div>
          </div>
          }

    </div>
)
}export default React.memo(CompDashboard)
