import React, { useState, useEffect, useCallback, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import debounce from 'lodash/debounce';

const CaseNotesInput = ({ initialValue, onCaseNotesChange }) => {
  const [localCaseNotes, setLocalCaseNotes] = useState(initialValue || '');

  // Handle immediate local state updates
  const handleLocalChange = (event) => {
    const value = event.target.value;
    setLocalCaseNotes(value); // Update the local state immediately
    debouncedChange(value);  // Debounced callback for higher-level state update
  };

  // Debounced function to update the higher-level state
  const debouncedChange = useMemo(
    () =>
      debounce((value) => {
        if (onCaseNotesChange) {
          onCaseNotesChange(value); // Invoke callback to update higher-level state
        }
      }, 500),
    [onCaseNotesChange]
  );

  // Sync local state with the initial value when it changes
  useEffect(() => {
    setLocalCaseNotes(initialValue || '');
  }, [initialValue]);

  return (
    <div className="flex flex-grow">
      <TextField
        hiddenLabel
        className="flex-grow py-0"
        InputProps={{
          classes: {
            input: 'py-2', // Adjust padding here
          },
        }}
        id="filled-hidden-label-normal"
        placeholder="Type any casenotes here"
        autoComplete="off"
        value={localCaseNotes}
        onChange={handleLocalChange}
        variant="filled"
      />
    </div>
  );
};

export default React.memo(CaseNotesInput);
